import React, { useContext, useEffect } from "react";
import { BreadCrumb } from "../../../components/breadcrumb";
import Title from "../../../components/title";
import { AuthContext } from "../../../contexts/auth";
import { AuditoriaProvider } from "../../../contexts/service";
import Form from "./form";
import styles from "./styles.module.scss";

const DashboardHabiltNovCad = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    useEffect(() => {
        setCurrentScreen(13);
    }, []);

    return (
        <AuditoriaProvider>
            <div className={`justify-content-center ${styles.containerList}`}>
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Habilitar novos cadastros de profissionais",
                        menu: {
                            item: "Configurações",
                            link: "",
                        },
                    }}
                />
                <Title title="Habilitar novos cadastros de profissionais" />
                <div className={styles.listagem}>
                    <Form />
                </div>
            </div>
        </AuditoriaProvider>
    );
};

export default DashboardHabiltNovCad;
