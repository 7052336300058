import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Api } from "../../../providers";
import Breadcrumb from "../../../components/breadcrumb/index";
import Title from "../../../components/title/index";
import imgNome from "../../../assets/images/nome.png";
import imgEmail from "../../../assets/images/email.png";
import imgCpf from "../../../assets/images/cpf.png";
import imgUnidade from "../../../assets/images/unidade.png";
import { AuthContext } from "../../../contexts/auth";

const EditarOperadorPage = () => {
    const navigate = useNavigate();

    const { setCurrentScreen } = useContext(AuthContext);
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");
    const [email, setEmail] = useState("");
    //Função
    const [funcoesLista, setFuncoesLista] = useState([]);
    const [funcaoId, setFuncaoId] = useState("0");
    //Perfil
    const [perfilLista, setPerfilLista] = useState([]);
    const [perfilId, setPerfilId] = useState("0");
    //Undade
    const [unidadesLista, setUnidadesLista] = useState([]);
    const [unidadeId, setUnidadeId] = useState("");
    const [ativo, setAtivo] = useState("");

    let mounted = true;
    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp = operador.operador.funcao.slug === "administrador" ? true : false;

    const handleRedirect = () => {
        navigate("/operador");
    };

    useEffect(() => {
        if (mounted) {
            if (!checkOp) {
                handleRedirect();
            }
            setCurrentScreen(3);
        }
        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        Api.get("/unidade/")
            .then((res) => {
                if (res.status === 200 && mounted) {
                    setUnidadesLista(res.data);
                    return true;
                }
                return false;
            })
            .then(() =>
                Api.get("/operador/" + idQuery).then((res) => {
                    if (res.status === 200 && mounted) {
                        setCpf(res.data.cpf);
                        setNome(res.data.nome);
                        setEmail(res.data.email);
                        setUnidadeId(res.data.unidadeId);
                        setFuncaoId(res.data.funcao?.id);
                        setAtivo(!!res.data.usuario?.flagAtivo);
                        setPerfilId(res.data.usuario?.perfis[0]?.id);
                    }
                }),
            );

        Api.get("/operador-funcao/").then((res) => {
            if (res.status === 200 && mounted) {
                setFuncoesLista(res.data);
            }
        });
        Api.get("/perfil/").then((res) => {
            if (res.status === 200 && mounted) {
                setPerfilLista(res.data);
            }
        });

        return () => {
            mounted = false;
        };
    }, [idQuery]);

    const handleSubmit = (event) => {
        event.preventDefault();

        let endpoint = "disable";

        if (ativo) {
            endpoint = "enable";
        }

        Api.post("/operador/" + idQuery + "/" + endpoint)
            .then((res) => {
                if (res)
                    toast.success("Atualizado com Sucesso", {
                        toastId: "success",
                    });
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "error",
                });
            });
    };

    if (!checkOp) {
        return<></>
    }

    return (
        <Container fluid className="containerForm justify-content-center">
            <Breadcrumb
                data={{
                    category: "Editar operador",
                    page: "Editar operador",
                    menu: {
                        item: "Configurações",
                        link: "/operador",
                    },
                }}
            />
            <Title title="Editar Operador" />
            <Container className="formulario">
                <form className="formulario-item" onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between">
                        <Form.Group style={{ width: "16%" }}>
                            <div className={"estado-cat " + ativo}>
                                {ativo ? "ativo" : "inativo"}
                            </div>
                        </Form.Group>
                        <Form.Group style={{ width: "42%" }}>
                            <Form.Label>
                                <img
                                    src={imgNome}
                                    alt="Nome"
                                    width="18px"
                                    height="18px"
                                />{" "}
                                {nome}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "42%" }}>
                            <Form.Label>
                                <img
                                    src={imgCpf}
                                    alt="Nome"
                                    width="18px"
                                    height="18px"
                                />{" "}
                                {cpf}
                            </Form.Label>
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Form.Group style={{ width: "16%" }}></Form.Group>
                        <Form.Group style={{ width: "42%" }}>
                            <Form.Label>
                                <img
                                    src={imgEmail}
                                    alt="Nome"
                                    width="18px"
                                    height="18px"
                                />{" "}
                                {email}
                            </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ width: "42%" }}>
                            <Form.Label>
                                <img
                                    src={imgUnidade}
                                    alt="Nome"
                                    width="18px"
                                    height="18px"
                                />{" "}
                                {unidadeId
                                    ? unidadesLista.filter(
                                          ({ id }) => id === unidadeId,
                                      )[0]?.nome
                                    : ""}
                            </Form.Label>
                        </Form.Group>
                    </div>
                </form>
            </Container>

            <Container className="formulario">
                <div className="d-flex justify-content-between p-4">
                    <div style={{ width: "60%" }}>
                        <Form.Group>
                            <Form.Label>Perfil do operador</Form.Label>
                            <Form.Select
                                className="select-pesquisa"
                                value={perfilId}
                                onChange={(e) => setPerfilId(e.target.value)}
                                disabled
                            >
                                <option value="0" key="0" disabled>
                                    Perfil
                                </option>
                                {perfilLista.map(({ id, nome }, key) => {
                                    return (
                                        <option value={id} key={key + 1}>
                                            {nome}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="my-1">
                            <Form.Label>{"Função do operador"}</Form.Label>
                            <Form.Select
                                className="select-pesquisa"
                                value={funcaoId}
                                onChange={(e) => setFuncaoId(e.target.value)}
                                disabled
                            >
                                <option value="0" key="0" disabled>
                                    Função
                                </option>
                                {funcoesLista.map(({ id, nome }, key) => {
                                    return (
                                        <option value={id} key={key + 1}>
                                            {nome}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div tyle={{ width: "40%" }}>
                        <Form.Group className="d-flex justify-content-between p-1">
                            <Form.Label>Status do operador</Form.Label>

                            <Form.Check
                                className="form-switch"
                                type="switch"
                                id="custom-switch"
                                label="Ativo"
                                checked={ativo}
                                onChange={(e) => setAtivo(!ativo)}
                            />
                        </Form.Group>
                    </div>
                </div>
                <Container className="d-flex justify-content-between">
                    <Form.Group className="my-5">
                        <button
                            className="button-submeter"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            Salvar
                        </button>
                    </Form.Group>
                </Container>
            </Container>
        </Container>
    );
};

export default EditarOperadorPage;
