import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

const Page404 = () => {
    return (
        <Grid
            container
            sx={{
                width: "100%",
                maxWidth: "1056px",
                margin: "32px 0px 0px -32px",
                padding: "10px 10px",
            }}
        >
            <div className="caminhodepao">
                <Link to="/categoria">Voltar</Link>
            </div>
            <div className="cabecalho">
                <h1>Página em construção!</h1>
            </div>
        </Grid>
    );
};

export default Page404;
