import { Stack } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUnidades } from "../../../hooks/useScriptRef";
import {
    newServiceMdalFalse,
    newServiceMdalTrue,
} from "../../../utils/constant";
import { CpfCnpjMaskRegex, TelMaskRegex } from "../../../utils/mask";
import btedit from "./../../../assets/images/bt-edit.png";
import btcalendar from "./../../../assets/images/calendar.png";
import { DateBR } from "./../../../utils/date/index";
import styles from "./profissionais.module.scss";

export function ListTable({ currentItems }) {
    const navigate = useNavigate();
    const unidadesServer = useUnidades();

    const getColorStatus = (status) => {
        switch (status) {
            case "Ativo":
                return "#95C11F";
            case "Bloqueado":
                return "#E60E19";
            case "Convocado":
                return "#F9B000";
            case "Excluído":
                return "#656A6E";
            case "Inabilitado":
                return "#E60E19";
            case "Inaivo":
                return "#656A6E";
            case "Inscrito":
                return "#0064B4";
            case "Licença":
                return "#E60E19";
            case "Suspenso":
                return "#F9B000";
            case "Treinamento":
                return "#0064B4";
            default:
                return "#656A6E";
        }
    };

    const handleRedirectEdit = (idProf, pendency) => {
        let serviceDoc = "";
        if (pendency) {
            serviceDoc = newServiceMdalTrue;
        } else {
            serviceDoc = newServiceMdalFalse;
        }
        navigate(`/profissional/editar?id=${idProf}&serviceDoc=${serviceDoc}`);
    };

    const getNameUnidadeProfissional = (idUnidade) => {
        const nameUnit = unidadesServer.find((line) => line.id == idUnidade);
        return nameUnit?.nome ?? "";
    };

    return (
        <div className="table-responsive">
            <table className={`table align-middle ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Situação</th>
                        <th>Nome</th>
                        <th>RG e CPF</th>
                        <th>Telefone</th>
                        <th>Data de inscrição</th>
                        <th>Unidade</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody data-test="table-body">
                    {currentItems.length > 0 ? (
                        currentItems.map((val, idx) => {
                            const colorTr =
                                idx % 2 == 0
                                    ? styles.colorTrOne
                                    : styles.colorTrTwo;
                            const colorTrLine =
                                val.pendencia || val.novoComprovante
                                    ? styles.colorTrRed
                                    : colorTr;
                            let { rg, cpf } = val;
                            if (rg === null) rg = "";
                            if (cpf === null) cpf = "";

                            return (
                                <tr
                                    className={colorTrLine}
                                    key={val.id.toString()}
                                >
                                    <td>
                                        <div
                                            className={styles.boxColor}
                                            style={{
                                                backgroundColor: getColorStatus(
                                                    val.situacao?.nome,
                                                ),
                                            }}
                                        >
                                            <span
                                                className={
                                                    styles.pillBadgesText
                                                }
                                            >
                                                {val.situacao?.nome}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        {val.nome.substring(0, 50) +
                                            " " +
                                            (val.nome.length > 50 ? "..." : "")}
                                    </td>
                                    <td>
                                        {`${CpfCnpjMaskRegex(
                                            rg,
                                        )} ${CpfCnpjMaskRegex(cpf)}`}
                                    </td>
                                    <td>
                                        {val.telefoneCelular
                                            ? TelMaskRegex(val.telefoneCelular)
                                            : ""}
                                        {val.telefoneContato
                                            ? ` / ${TelMaskRegex(
                                                  val.telefoneContato,
                                              )}`
                                            : ""}
                                    </td>
                                    <td>{DateBR(val.dataCadastro)}</td>
                                    <td>
                                        {getNameUnidadeProfissional(
                                            val.unidadeId,
                                        )}
                                    </td>
                                    <td>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                                cursor:
                                                    val.situacao?.nome !==
                                                    "Excluído"
                                                        ? "pointer"
                                                        : "auto",
                                            }}
                                        >
                                            {val.situacao?.nome !==
                                                "Excluído" && (
                                                <>
                                                    <Link
                                                        to={
                                                            "/profissional/agenda/?id=" +
                                                            val.id
                                                        }
                                                    >
                                                        <img
                                                            src={btcalendar}
                                                            alt="agenda"
                                                            title="agenda"
                                                        />
                                                    </Link>
                                                    <div
                                                        onClick={() =>
                                                            handleRedirectEdit(
                                                                val.id,
                                                                val.pendencia,
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={btedit}
                                                            alt="Editar"
                                                            title="Editar"
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </Stack>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
        </div>
    );
}
