import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import MyDatePicker from "./../../../components/datepicker/";
import { Api } from "../../../providers";
import styles from "./styles.module.scss";
import { DateBR, FormatDate } from "../../../utils/date";
import ResultNotFound from "../../../components/resultNotFound";

export default function Section1({ setLoadingModal, idClient }) {
    const navigate = useNavigate();

    const [specificDate, setSpecificDate] = useState("");
    const [fieldDate, setFieldDate] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tarefaId, setTarefaId] = useState(0);
    const [requestData, setRequestData] = useState([]);
    const [occurrenceType, setOccurrenceType] = useState([]);
    const [observation, setObservation] = useState("");
    const [textObsError, setTextObsError] = useState("Campo obrigatório");
    const [textReqError, setTextReqError] = useState("Campo obrigatório");
    const [occurTypeError, setOccurTypeError] = useState("Campo obrigatório");
    const [dateError, setDateError] = useState("Campo obrigatório");
    const [currentDateTime, setCurrentDateTime] = useState("");
    const [requestId, setRequestId] = useState("");
    const [occurrenceId, setOccurrenceId] = useState(0);
    const [onFocusStartDate, setOnFocusStartDate] = useState(false);

    let mounted = true;
    const itemsPerPage = 5;
    const operador = JSON.parse(localStorage.getItem("user"));

    const resultRequest = async () => {
        await Promise.all([
            Api.get(`/restricao/solicitante`).then((res) => {
                if (mounted && res.status === 200) {
                    const result = res.data.filter(data => data !== "Profissional");
                    setRequestData(result);
                }
            }),

            Api.get(`/ocorrencia/tipo?pontuacao=&nome=`).then((res) => {
                if (mounted && res.status === 200) {
                    const result = res.data.filter(val => val.slug !== "servico-cancelado-pelo-profissional")
                    setOccurrenceType(result);
                }
            }),

            Api.get("/system/date")
            .then((res) => {
                if (mounted) {
                    setCurrentDateTime(res.data.dateBr+" "+res.data.hoursBr);
                }
            },
        )
        ]);
    }

    useEffect(() => {
        setFieldDate([]);
        setTotalPages(0);
        setTarefaId(0);
        return () => {
            mounted = false;
        };
    }, [idClient]);

    useEffect(() => {
        resultRequest();
        return () => {
            mounted = false;
        };
    }, []);

    const inputSearch = (text) => {
        const checkDate = moment(text, 'YYYY-MM-DD').valueOf() > 0 ? true : false;
        if (text) {
            const dateSelected = new Date(text);
            setSpecificDate(dateSelected);
            if (checkDate) {
                setDateError("");
            } else {
                setDateError("Campo obrigatório");
            }
        } else {
            setSpecificDate("");
            setDateError("Campo obrigatório");
        }
    }

    const handleBtn = (event) => {
        const selectedData = FormatDate(specificDate);
        event.preventDefault();
        if ((selectedData.length <= 0 || !selectedData.trim()) && idClient <= 0) {
            return;
        }
        setLoadingModal(true);
        Api.get(`/ocorrencia/cliente/${idClient}/pagination/0/${itemsPerPage}?data=${selectedData}`).then((res) => {
            if (mounted && res.status === 200) {
                setFieldDate(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoadingModal(false);
            }
        }).catch(_ => {
            if (mounted) {
                setLoadingModal(false);
            }
        });
    }

    const handleSelected = (id) => {
        const resultId = Number(id);
        if (resultId > 0) {
            setTarefaId(resultId);
        }
    }

    function textObs(desc) {
        if (desc.length <= 0) {
            setObservation("");
            setTextObsError("Campo obrigatório");
        } else {
            if (desc.length <= 1500 && desc.trim()) {
                setObservation(desc);
                setTextObsError("");
            }
        }
    }

    const nextPage = (page = 0) => {
        setLoadingModal(true);
        const selectedData = FormatDate(specificDate);
        Api.get(`/ocorrencia/cliente/${idClient}/pagination/${page}/${itemsPerPage}?data=${selectedData}`).then((res) => {
            setFieldDate(res.data.content);
            setTotalPages(res.data.totalPages);
            setLoadingModal(false);
        }).catch(_ => {
            setLoadingModal(false);
        });
    }

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    const valorServico = (row) => {
        if (row.valor) return `R$ ${row.valor}`;
        if (row.servico.servicoValor) return `R$ ${row.servico.servicoValor}`;
        if (row.servico.taxaVisitaValor)
            return `R$ ${row.servico.taxaVisitaValor}`;
        return `-`;
    };

    const checkRequester = (text) => {
        const checkOption = [
            "Cliente",
            "Profissional"
        ];
        if (checkOption.includes(text)) {
            setRequestId(text);
            setTextReqError("");
        } else {
            setRequestId("");
            setTextReqError("Campo obrigatório");
        }
    }

    const checkOccurType = (text) => {
        const resultId = Number(text);
        if (resultId > 0) {
            setOccurrenceId(resultId);
            setOccurTypeError("");
        } else {
            setOccurTypeError("Campo obrigatório");
            setOccurrenceId(0);
        }
    }

    const handleRedirect = () => {
        navigate("/ocorrencias");
    };
    
    const submitData = () => {
        setLoadingModal(true);
        const dataSend = {
            status: "",
            descricao: observation,
            tarefaId: tarefaId,
            ocorrenciaTipoId: occurrenceId,
            solicitante: requestId,
        }

        Api.post(`/ocorrencia/cliente/${idClient}`, dataSend).then((res) => {
            if (mounted && res.status === 200) {
                setLoadingModal(false);
                toast.success("Ocorrência aberta com sucesso", {
                    toastId: "success",
                });
                handleRedirect();
            }
        }).catch(error => {
            if (mounted) {
                setLoadingModal(false);
                const checkErro = error;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "msg",
                });
            }
        });
    }

    const checkDate = moment(specificDate, 'YYYY-MM-DD').valueOf() > 0 ? true : false;
    const checkButtonSubmit = idClient > 0 && tarefaId > 0 && requestId.length > 0 && occurrenceId > 0 && observation.length > 0 ? false : true;
    const checkButtonSearch = checkDate && idClient > 0 ? false : true;

    const focusStartDate = () => {
        if (!checkDate) {
            setOnFocusStartDate(true);
        }
    }

    const blurStartDate = () => {
        setOnFocusStartDate(false);
    }

    const checkMyDateStart = checkDate ? styles.labelDateEspOff : styles.labelDateEspOn;
    const checkDateStart = onFocusStartDate ? checkMyDateStart : styles.labelDateEspOff;
    const myDateStart = checkDate ? styles.labelDateOk : checkDateStart;

    return (
        <>

            <div>
                <div className="d-flex justify-content-center align-items-center container">
                    <div className="d-flex flex-column">
                        <div className={`${styles.containerInput}`}>
                            <div className="d-flex flex-column">
                                <div className={`col-sm-8 ${myDateStart} ${styles.inputData}`}>
                                    <MyDatePicker
                                        value={specificDate}
                                        title="Data específica"
                                        placeholder="Data específica"
                                        onChange={(e) => inputSearch(e)}
                                        onFocus={focusStartDate}
                                        onBlur={blurStartDate}
                                    />
                                </div>
                                <span className={styles.requiredField}>{dateError}</span>
                            </div>
                            <div>
                                <button
                                    className={checkButtonSearch ? styles.buttonSearchBlock : styles.buttonSearch}
                                    type="submit"
                                    onClick={checkButtonSearch ? () => {} : (e) => handleBtn(e)}
                                    disabled={checkButtonSearch}
                                >
                                    Pesquisar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`d-flex flex-column justify-content-center align-items-center container table-responsive`}>
                    
                    {fieldDate.length > 0 && (
                        <table className={`table align-middle ${styles.table}`}>
                            <thead>
                                <tr>
                                    <th />
                                    <th>Nome do profissional</th>
                                    <th>Serviço</th>
                                    <th>Data</th>
                                    <th>Hora</th>
                                    <th>Endereço</th>
                                    <th>Valor do Serviço (R$)</th>
                                </tr>
                            </thead>
                            <tbody data-test="table-body" className={styles.tbodyTable}>
                                {fieldDate.length > 0 && fieldDate.map(val => {

                                    const titulo = val.profissionais[0].enderecos[0].titulo;
                                    const logradouro = val.profissionais[0].enderecos[0].logradouro+" - ";
                                    const referencia = val.profissionais[0].enderecos[0].referencia ? val.profissionais[0].enderecos[0].referencia+" - " : "";
                                    const numero = val.profissionais[0].enderecos[0].numero;
                                    const bairro = val.profissionais[0].enderecos[0].bairro;
                                    const cidade = val.profissionais[0].enderecos[0].cidade;
                                    const enderecoCompleto = `${logradouro} ${referencia} Nº ${numero}, ${bairro} - ${cidade} (${titulo})`;

                                    return (
                                        <tr onClick={() => handleSelected(val.id)} key={val.id} className={tarefaId === val.id ? styles.textSelected : styles.textNoSelected}>
                                            <td>
                                                {tarefaId === val.id ? (
                                                <div className={styles.radioCircle}>
                                                    <span className={styles.circleBall} />
                                                </div>
                                                ) : (
                                                    <div className={styles.radCirNoSelected} />
                                                )}
                                            </td>
                                            <td>{val.profissionais[0].nome}</td>
                                            <td>{val.servico.servico.nome}</td>
                                            <td>{DateBR(val.datas[0])}</td>
                                            <td>{val.hora.substring(0,5)}</td>
                                            <td>{enderecoCompleto}</td>
                                            <td>{valorServico(val)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}

                    {fieldDate.length <= 0 && (
                        <ResultNotFound style={{
                            marginTop: 10,
                            marginBottom: 10,
                            alignSelf: "start",
                        }} />
                    )}
                    
                    <div className={styles.reactPaginateModal}>
                        <ReactPaginate
                            breakLabel="..."
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={totalPages}
                            previousLabel="<"
                            nextLabel=">"
                            renderOnZeroPageCount={null}
                            marginPagesDisplayed={2}
                            containerClassName={styles.pagination}
                            pageClassName={styles.pageItem}
                            pageLinkClassName={"page-link"}
                            previousLinkClassName={styles.pageLink}
                            nextClassName={styles.pageItem}
                            nextLinkClassName={styles.pageLink}
                            activeClassName={"active"}
                            forcePage={currentPage - 1}
                            disabledClassName={styles.disabled}
                        />
                    </div>
                </div>

                <div className={`d-flex flex-column container table-responsive`}>
                    <div className={styles.containerSelect}>
                        <div className={styles.fieldSelect}>
                            <div className={textReqError ? styles.formGroupError : ''}>
                                <label className={`form-label ${textReqError ? styles.textLabelRed : styles.textLabel}`}>Solicitante</label>
                                <select className="form-select"
                                    onChange={(e) =>
                                        checkRequester(
                                            e.target.value,
                                        )
                                    }
                                >
                                    <option value="" selected>Selecione o solicitante</option>
                                    {requestData.length > 0 && requestData.map(data => (
                                        <option key={data} value={data}>{data}</option>
                                    ))}
                                </select>
                                <span className={styles.requiredField}>{textReqError}</span>
                            </div>
                        </div>
                        <div className={styles.fieldSelect}>
                            <div className={occurTypeError ? styles.formGroupError : ''}>
                                <label className={`form-label ${occurTypeError ? styles.textLabelRed : styles.textLabel}`}>Tipo da ocorrência</label>
                                <select className="form-select"
                                    onChange={(e) => checkOccurType(e.target.value)}
                                >
                                    <option value="0" selected>Selecione o tipo da ocorrência</option>
                                    {occurrenceType.length > 0 && occurrenceType.map(data => (
                                        <option key={data.id} value={data.id}>{data.nome}</option>
                                    ))}
                                </select>
                                <span className={styles.requiredField}>{occurTypeError}</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.containerObservation}>
                        <div>
                            <label className={`form-label ${textObsError ? styles.textLabelRed : styles.textLabel}`}>Observação</label>
                            <div className={textObsError ? styles.formGroupError : ''}>
                                <textarea
                                    className="form-control"
                                    placeholder="Observação"
                                    rows="3"
                                    onChange={(e) =>
                                        textObs(e.target.value)
                                    }
                                    value={observation}
                                />
                            </div>
                        </div>
                        <span className={styles.requiredField}>{textObsError}</span>
                        <div>
                            <div>Operador {operador.operador.nome} - {currentDateTime}</div>
                            <div>
                                <span>{observation.length}</span>
                                <span>/1500 caracteres</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.containerButton}>
                    <button
                        className={checkButtonSubmit ? styles.buttonSearchBlock : styles.buttonSearch}
                        type="submit"
                        onClick={checkButtonSubmit ? () => {} : () => submitData()}
                        disabled={checkButtonSubmit ? true : false}
                    >
                        Abrir ocorrência
                    </button>
                </div>
            </div>
        </>
    );
}
