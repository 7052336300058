import moment from "moment";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import MyDatePicker from "../../../components/datepicker";
import ResultNotFound from "../../../components/resultNotFound";
import { Api } from "../../../providers";
import { DateBR, FormatDate } from "../../../utils/date";
import { CpfCnpjMaskRegex, TelMaskRegex } from "../../../utils/mask";
import styles from "./styles.module.scss";

export default function Section2({ setLoadingModal, idClient }) {
    const navigate = useNavigate();

    const [textObsError, setTextObsError] = useState("Campo obrigatório");
    const [textReqError, setTextReqError] = useState("Campo obrigatório");
    const [occurTypeError, setOccurTypeError] = useState("Campo obrigatório");
    const [serviceError, setServiceError] = useState("Campo obrigatório");
    const [startDateError, setStartDateError] = useState("Campo obrigatório");
    const [endDateError, setEndDateError] = useState("Campo obrigatório");
    const [nameProfError, setNameProfError] = useState("Campo obrigatório");
    const [currentDateTime, setCurrentDateTime] = useState("");
    const [observation, setObservation] = useState("");
    const [requestId, setRequestId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [nameProf, setNameProf] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [tarefaId, setTarefaId] = useState(0);
    const [idProf, setIdProf] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalPagesFilter, setTotalPagesFilter] = useState(0);
    const [occurrenceId, setOccurrenceId] = useState(0);
    const [serviceId, setServiceId] = useState(0);
    const [tarefaIdFilter, setTarefaIdFilter] = useState(0);
    const [profData, setProfData] = useState([]);
    const [requestData, setRequestData] = useState([]);
    const [occurrenceType, setOccurrenceType] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [filterResult, setFilterResult] = useState([]);
    const [onFocusStartDate, setOnFocusStartDate] = useState(false);
    const [onFocusEndDate, setOnFocusEndDate] = useState(false);

    let mounted = true;
    const itemsPerPage = 5;
    const operador = JSON.parse(localStorage.getItem("user"));

    const resultRequest = async () => {
        await Promise.all([
            Api.get(`/restricao/solicitante`).then((res) => {
                if (mounted && res.status === 200) {
                    const result = res.data.filter(
                        (data) => data !== "Profissional",
                    );
                    setRequestData(result);
                }
            }),

            Api.get(`/ocorrencia/tipo?pontuacao=&nome=`).then((res) => {
                if (mounted && res.status === 200) {
                    const result = res.data.filter(
                        (val) =>
                            val.slug !== "servico-cancelado-pelo-profissional",
                    );
                    setOccurrenceType(result);
                }
            }),

            Api.get("/system/date").then((res) => {
                if (mounted) {
                    setCurrentDateTime(
                        res.data.dateBr + " " + res.data.hoursBr,
                    );
                }
            }),
        ]);
    };

    const cleanInfo = () => {
        setProfData([]);
        setServiceData([]);
        setFilterResult([]);
        setTarefaId(0);
        setIdProf(0);
        setTarefaIdFilter(0);
        setServiceId(0);
        setTotalPages(0);
        setTotalPagesFilter(0);
    };

    useEffect(() => {
        resultRequest();
        cleanInfo();
        return () => {
            mounted = false;
        };
    }, [idClient]);

    const inputSearch = (text) => {
        if (text.length > 0 && text.trim()) {
            setNameProf(text);
            setNameProfError("");
        } else {
            setNameProfError("Campo obrigatório");
            setNameProf("");
            cleanInfo();
        }
    };

    const handleBtn = (event) => {
        event.preventDefault();
        if (nameProf.length <= 0 && !nameProf.trim()) {
            return;
        }
        setLoadingModal(true);
        Api.get(
            `/ocorrencia/cliente/${idClient}/pagination/0/${itemsPerPage}?fields=${nameProf}`,
        )
            .then((res) => {
                if (mounted && res.status === 200) {
                    setProfData(res.data.content);
                    setTotalPages(res.data.totalPages);
                    setLoadingModal(false);
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoadingModal(false);
                }
            });
    };

    const handleService = (profId) => {
        setLoadingModal(true);
        Api.get(
            `/ocorrencia/cliente/${idClient}/profissional/${profId}/servico`,
        )
            .then((res) => {
                setServiceData(res.data);
                setLoadingModal(false);
            })
            .catch((_) => {
                setLoadingModal(false);
            });
    };

    const handleSelected = (id, profId) => {
        const resultId = Number(id);
        if (resultId > 0) {
            setServiceData([]);
            setFilterResult([]);
            setTarefaId(resultId);
            setTarefaIdFilter(0);
            setServiceId(0);
            setServiceError("Campo obrigatório");
            setIdProf(profId);
            handleService(profId);
        } else {
            setTarefaId(0);
            setIdProf(0);
            setTarefaIdFilter(0);
            setServiceId(0);
            setFilterResult([]);
            setServiceData([]);
            setServiceError("Campo obrigatório");
        }
    };

    const handleSelectedFilter = (id) => {
        const resultId = Number(id);
        if (resultId > 0) {
            setTarefaIdFilter(resultId);
        } else {
            setTarefaIdFilter(0);
        }
    };

    const nextPage = (page = 0) => {
        setLoadingModal(true);
        Api.get(
            `/ocorrencia/cliente/${idClient}/pagination/${page}/${itemsPerPage}?fields=${nameProf}`,
        )
            .then((res) => {
                setProfData(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoadingModal(false);
            })
            .catch((_) => {
                setLoadingModal(false);
            });
    };

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    const checkRequest = (text) => {
        const checkOption = ["Cliente", "Profissional"];
        if (checkOption.includes(text)) {
            setRequestId(text);
            setTextReqError("");
        } else {
            setRequestId("");
            setTextReqError("Campo obrigatório");
        }
    };

    const checkOccurType = (text) => {
        const resultId = Number(text);
        if (resultId > 0) {
            setOccurrenceId(resultId);
            setOccurTypeError("");
        } else {
            setOccurTypeError("Campo obrigatório");
            setOccurrenceId(0);
        }
    };

    const checkService = (text) => {
        const resultId = Number(text);
        if (resultId > 0) {
            setServiceId(resultId);
            setServiceError("");
        } else {
            setServiceError("Campo obrigatório");
            setServiceId(0);
        }
    };

    function textObs(desc) {
        if (desc.length <= 0) {
            setObservation("");
            setTextObsError("Campo obrigatório");
        } else {
            if (desc.length <= 1500 && desc.trim()) {
                setObservation(desc);
                setTextObsError("");
            }
        }
    }

    const handleStartDate = (text) => {
        const checkDate01 = moment(text, "YYYY-MM-DD").valueOf();
        const checkDate02 = moment(endDate, "YYYY-MM-DD").valueOf();
        const subtractDate = moment(endDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        const checkDateStart =
            moment(text, "YYYY-MM-DD").valueOf() > 0 ? true : false;
        if (text && checkDateStart) {
            const dateSelected = new Date(text);
            if (!isNaN(checkDate02)) {
                if (checkDate01 <= checkDate02) {
                    setStartDate(dateSelected);
                    setStartDateError("");
                    setEndDateError("");
                } else {
                    if (checkDateStart) {
                        setStartDate(subtractDate);
                    } else {
                        setStartDate(dateSelected);
                    }
                    setStartDateError("");
                }
            } else {
                setStartDate(dateSelected);
                setStartDateError("");
            }
        } else {
            setStartDate("");
            setStartDateError("Campo obrigatório");
        }
    };

    const handleEndDate = (text) => {
        const checkDate01 = moment(text, "YYYY-MM-DD").valueOf();
        const checkDate02 = moment(startDate, "YYYY-MM-DD").valueOf();
        const addDay = moment(startDate).add(1, "days").format("YYYY-MM-DD");
        const checkDateEnd =
            moment(text, "YYYY-MM-DD").valueOf() > 0 ? true : false;
        if (text && checkDateEnd) {
            const dateSelected = new Date(text);
            if (!isNaN(checkDate02)) {
                if (checkDate01 >= checkDate02) {
                    setEndDate(dateSelected);
                    setEndDateError("");
                    setStartDateError("");
                } else {
                    if (checkDateEnd) {
                        setEndDate(addDay);
                    } else {
                        setEndDate(dateSelected);
                    }
                    setEndDateError("");
                }
            } else {
                setEndDate(dateSelected);
                setEndDateError("");
            }
        } else {
            setEndDate("");
            setEndDateError("Campo obrigatório");
        }
    };

    const handleResult = () => {
        setLoadingModal(true);
        const date01 = FormatDate(startDate);
        const date02 = FormatDate(endDate);
        Api.get(
            `/ocorrencia/cliente/${idClient}/profissional/${idProf}/pagination/0/${itemsPerPage}?servicoId=${serviceId}&dataInicio=${date01}&dataFim=${date02}`,
        )
            .then((res) => {
                setFilterResult(res.data.content);
                setTotalPagesFilter(res.data.totalPages);
                setLoadingModal(false);
            })
            .catch((_) => {
                setLoadingModal(false);
            });
    };

    const nextPageFilter = (page = 0) => {
        setLoadingModal(true);
        const date01 = FormatDate(startDate);
        const date02 = FormatDate(endDate);
        Api.get(
            `/ocorrencia/cliente/${idClient}/profissional/${idProf}/pagination/${page}/${itemsPerPage}?servicoId=${serviceId}&dataInicio=${date01}&dataFim=${date02}`,
        )
            .then((res) => {
                setFilterResult(res.data.content);
                setTotalPagesFilter(res.data.totalPages);
                setLoadingModal(false);
            })
            .catch((_) => {
                setLoadingModal(false);
            });
    };

    const handlePageClickFilter = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPageFilter(event.selected);
    };

    const valorServico = (row) => {
        if (row.valor) return `R$ ${row.valor}`;
        if (row.servico.servicoValor) return `R$ ${row.servico.servicoValor}`;
        if (row.servico.taxaVisitaValor)
            return `R$ ${row.servico.taxaVisitaValor}`;
        return `-`;
    };

    const handleRedirect = () => {
        navigate("/ocorrencias");
    };

    const submitData = () => {
        setLoadingModal(true);
        const dataSend = {
            status: "",
            descricao: observation,
            tarefaId: tarefaIdFilter,
            ocorrenciaTipoId: occurrenceId,
            solicitante: requestId,
        };

        Api.post(`/ocorrencia/cliente/${idClient}`, dataSend)
            .then((res) => {
                if (mounted && res.status === 200) {
                    setLoadingModal(false);
                    toast.success("Ocorrência aberta com sucesso", {
                        toastId: "success",
                    });
                    handleRedirect();
                }
            })
            .catch((error) => {
                if (mounted) {
                    setLoadingModal(false);
                    const checkErro = error;
                    toast.error(
                        `Ops! ${checkErro.response.data.message?.error}`,
                        {
                            toastId: "msg",
                        },
                    );
                }
            });
    };

    const checkDate01 =
        moment(startDate, "YYYY-MM-DD").valueOf() > 0 ? true : false;
    const checkDate02 =
        moment(endDate, "YYYY-MM-DD").valueOf() > 0 ? true : false;
    const checkFilterSubmit =
        serviceId > 0 && checkDate01 && checkDate02 ? false : true;
    const checkButtonSubmit =
        tarefaIdFilter > 0 &&
        requestId.length > 0 &&
        occurrenceId > 0 &&
        observation.length > 0
            ? false
            : true;
    const checkButtonSearch =
        nameProf.length > 0 && idClient > 0 ? false : true;

    const focusStartDate = () => {
        if (!checkDate01) {
            setOnFocusStartDate(true);
        }
    };

    const blurStartDate = () => {
        setOnFocusStartDate(false);
    };

    const focusEndDate = () => {
        if (!checkDate02) {
            setOnFocusEndDate(true);
        }
    };

    const blurEndDate = () => {
        setOnFocusEndDate(false);
    };

    const checkMyDateStart = checkDate01
        ? styles.labelDateOff
        : styles.labelDateOn;
    const checkDateStart = onFocusStartDate
        ? checkMyDateStart
        : styles.labelDateOff;
    const myDateStart = checkDate01 ? styles.labelDateOk : checkDateStart;

    const checkMyDateEnd = checkDate02
        ? styles.labelDateOff
        : styles.labelDateOn;
    const checkDateEnd = onFocusEndDate ? checkMyDateEnd : styles.labelDateOff;
    const myDateEnd = checkDate02 ? styles.labelDateOk : checkDateEnd;

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center container">
                <div className="d-flex flex-column">
                    <div className={`${styles.containerInput}`}>
                        <div
                            className={`col-sm-8 ${
                                nameProfError ? styles.formGroupError : ""
                            }`}
                        >
                            <input
                                onChange={(e) => inputSearch(e.target.value)}
                                value={nameProf}
                                type="text"
                                className={`form-control ${styles.inputSearch}`}
                                title="Nome do profissional"
                                placeholder="Nome do profissional"
                                aria-label="Nome do profissional"
                            />
                            <span className={styles.requiredField}>
                                {nameProfError}
                            </span>
                        </div>
                        <div>
                            <button
                                className={
                                    checkButtonSearch
                                        ? styles.buttonSearchBlock
                                        : styles.buttonSearch
                                }
                                type="submit"
                                onClick={
                                    checkButtonSearch
                                        ? () => {}
                                        : (e) => handleBtn(e)
                                }
                                disabled={checkButtonSearch}
                            >
                                Pesquisar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`d-flex flex-column justify-content-center align-items-center container table-responsive`}
            >
                {profData.length > 0 && (
                    <table className={`table align-middle ${styles.table}`}>
                        <thead>
                            <tr>
                                <th />
                                <th>Profissional</th>
                                <th>Unidade</th>
                                <th>Telefone</th>
                                <th>CPF</th>
                            </tr>
                        </thead>
                        <tbody
                            data-test="table-body"
                            className={styles.tbodyTable}
                        >
                            {profData.length > 0 &&
                                profData.map((val) => {
                                    const unidadePrincipal =
                                        val.profissionais[0].unidades.find(
                                            (data) =>
                                                data.id ===
                                                val.profissionais[0].unidadeId,
                                        );
                                    return (
                                        <tr
                                            onClick={() =>
                                                handleSelected(
                                                    val.id,
                                                    val.profissionais[0].id,
                                                )
                                            }
                                            className={
                                                tarefaId === val.id
                                                    ? styles.textSelected
                                                    : styles.textNoSelected
                                            }
                                        >
                                            <td>
                                                {tarefaId == val.id ? (
                                                    <div
                                                        className={
                                                            styles.radioCircle
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.circleBall
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.radCirNoSelected
                                                        }
                                                    />
                                                )}
                                            </td>

                                            <td className={styles.nomeProf}>
                                                {val.profissionais[0].nome}
                                            </td>
                                            <td>{unidadePrincipal?.nome}</td>
                                            <td>
                                                {TelMaskRegex(
                                                    val.profissionais[0]
                                                        .telefoneCelular,
                                                )}
                                            </td>
                                            <td>
                                                {CpfCnpjMaskRegex(
                                                    val.profissionais[0].cpf,
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                )}

                {profData.length <= 0 && (
                    <ResultNotFound
                        style={{
                            marginTop: 10,
                            alignSelf: "start",
                        }}
                    />
                )}

                <div className={styles.reactPaginateModal}>
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pageItem}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={styles.pageLink}
                        nextClassName={styles.pageItem}
                        nextLinkClassName={styles.pageLink}
                        activeClassName={"active"}
                        forcePage={currentPage - 1}
                        disabledClassName={styles.disabled}
                    />
                </div>
            </div>

            <div className={`d-flex flex-rows container table-responsive`}>
                <div className={styles.containerFilter}>
                    <div className={styles.subContainerFilter}>
                        <div className={styles.textFilter}>
                            Filtrar resultados
                        </div>

                        <div className={styles.containerResult}>
                            <div className={styles.resultSelect}>
                                <div
                                    className={`d-flex flex-column ${
                                        serviceError
                                            ? styles.formGroupError
                                            : ""
                                    }`}
                                >
                                    <select
                                        className="form-select"
                                        onChange={(e) =>
                                            checkService(e.target.value)
                                        }
                                    >
                                        <option value="0" selected>
                                            Serviço
                                        </option>
                                        {serviceData.length > 0 &&
                                            serviceData.map((data) => (
                                                <option
                                                    key={data.id}
                                                    value={data.id}
                                                >
                                                    {data.nome}
                                                </option>
                                            ))}
                                    </select>
                                    <span className={styles.requiredField}>
                                        {serviceError}
                                    </span>
                                </div>
                            </div>
                            <div className={styles.filterDate}>
                                <div
                                    className={`${myDateStart} ${
                                        startDateError
                                            ? styles.formGroupError
                                            : ""
                                    }`}
                                >
                                    <MyDatePicker
                                        title="Data início"
                                        value={startDate}
                                        placeholder="Data início"
                                        onChange={(e) => handleStartDate(e)}
                                        onFocus={focusStartDate}
                                        onBlur={blurStartDate}
                                    />
                                </div>
                                <span className={styles.requiredField}>
                                    {startDateError}
                                </span>
                            </div>
                            <div className={styles.filterDate}>
                                <div
                                    className={`${myDateEnd} ${
                                        endDateError
                                            ? styles.formGroupError
                                            : ""
                                    }`}
                                >
                                    <MyDatePicker
                                        title="Data fim"
                                        value={endDate}
                                        placeholder="Data fim"
                                        onChange={(e) => handleEndDate(e)}
                                        onFocus={focusEndDate}
                                        onBlur={blurEndDate}
                                    />
                                </div>
                                <span className={styles.requiredField}>
                                    {endDateError}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button
                            className={
                                checkFilterSubmit
                                    ? styles.buttonSearchBlock
                                    : styles.buttonSearch
                            }
                            type="submit"
                            onClick={
                                checkFilterSubmit
                                    ? () => {}
                                    : () => handleResult()
                            }
                            disabled={checkFilterSubmit}
                        >
                            Pesquisar
                        </button>
                    </div>
                </div>
            </div>

            <div
                className={`d-flex flex-column justify-content-center align-items-center container table-responsive`}
            >
                {filterResult.length > 0 && (
                    <table className={`table align-middle ${styles.table}`}>
                        <thead>
                            <tr>
                                <th />
                                <th>Serviço</th>
                                <th>Data</th>
                                <th>Hora</th>
                                <th>Endereço</th>
                                <th>Valor do Serviço (R$)</th>
                            </tr>
                        </thead>
                        <tbody
                            data-test="table-body"
                            className={styles.tbodyTable}
                        >
                            {filterResult.length > 0 &&
                                filterResult.map((val) => {
                                    const titulo =
                                        val.profissionais[0].enderecos[0]
                                            .titulo;
                                    const logradouro =
                                        val.profissionais[0].enderecos[0]
                                            .logradouro + " - ";
                                    const referencia = val.profissionais[0]
                                        .enderecos[0].referencia
                                        ? val.profissionais[0].enderecos[0]
                                              .referencia + " - "
                                        : "";
                                    const numero =
                                        val.profissionais[0].enderecos[0]
                                            .numero;
                                    const bairro =
                                        val.profissionais[0].enderecos[0]
                                            .bairro;
                                    const cidade =
                                        val.profissionais[0].enderecos[0]
                                            .cidade;
                                    const enderecoCompleto = `${logradouro} ${referencia} Nº ${numero}, ${bairro} - ${cidade} (${titulo})`;

                                    return (
                                        <tr
                                            onClick={() =>
                                                handleSelectedFilter(val.id)
                                            }
                                            key={val.id}
                                            className={
                                                tarefaIdFilter === val.id
                                                    ? styles.textSelected
                                                    : styles.textNoSelected
                                            }
                                        >
                                            <td>
                                                {tarefaIdFilter === val.id ? (
                                                    <div
                                                        className={
                                                            styles.radioCircle
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.circleBall
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.radCirNoSelected
                                                        }
                                                    />
                                                )}
                                            </td>
                                            <td>{val.servico.servico.nome}</td>
                                            <td>{DateBR(val.datas[0])}</td>
                                            <td>{val.hora.substring(0, 5)}</td>
                                            <td>{enderecoCompleto}</td>
                                            <td>{valorServico(val)}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                )}

                {filterResult.length <= 0 && (
                    <ResultNotFound
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            alignSelf: "start",
                        }}
                    />
                )}

                <div className={styles.reactPaginateModal}>
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClickFilter}
                        pageRangeDisplayed={5}
                        pageCount={totalPagesFilter}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pageItem}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={styles.pageLink}
                        nextClassName={styles.pageItem}
                        nextLinkClassName={styles.pageLink}
                        activeClassName={"active"}
                        forcePage={currentPage - 1}
                        disabledClassName={styles.disabled}
                    />
                </div>
            </div>

            <div className={`d-flex flex-column container table-responsive`}>
                <div className={styles.containerSelect}>
                    <div className={styles.fieldSelect}>
                        <div
                            className={
                                textReqError ? styles.formGroupError : ""
                            }
                        >
                            <label
                                className={`form-label ${
                                    textReqError
                                        ? styles.textLabelRed
                                        : styles.textLabel
                                }`}
                            >
                                Solicitante
                            </label>
                            <select
                                className="form-select"
                                onChange={(e) => checkRequest(e.target.value)}
                            >
                                <option value="0" selected>
                                    Selecione o solicitante
                                </option>
                                {requestData.length > 0 &&
                                    requestData.map((data) => (
                                        <option key={data} value={data}>
                                            {data}
                                        </option>
                                    ))}
                            </select>
                            <span className={styles.requiredField}>
                                {textReqError}
                            </span>
                        </div>
                    </div>
                    <div className={styles.fieldSelect}>
                        <div
                            className={
                                occurTypeError ? styles.formGroupError : ""
                            }
                        >
                            <label
                                className={`form-label ${
                                    occurTypeError
                                        ? styles.textLabelRed
                                        : styles.textLabel
                                }`}
                            >
                                Tipo da ocorrência
                            </label>
                            <select
                                className="form-select"
                                onChange={(e) => checkOccurType(e.target.value)}
                            >
                                <option value="0" selected>
                                    Selecione o tipo da ocorrência
                                </option>
                                {occurrenceType.length > 0 &&
                                    occurrenceType.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.nome}
                                        </option>
                                    ))}
                            </select>
                            <span className={styles.requiredField}>
                                {occurTypeError}
                            </span>
                        </div>
                    </div>
                </div>

                <div className={styles.containerObservation}>
                    <div>
                        <label
                            className={`form-label ${
                                textObsError
                                    ? styles.textLabelRed
                                    : styles.textLabel
                            }`}
                        >
                            Observação
                        </label>
                        <div
                            className={
                                textObsError ? styles.formGroupError : ""
                            }
                        >
                            <textarea
                                className="form-control"
                                placeholder="Observação"
                                rows="3"
                                onChange={(e) => textObs(e.target.value)}
                                value={observation}
                            />
                        </div>
                    </div>
                    <span className={styles.requiredField}>{textObsError}</span>
                    <div>
                        <div>
                            Operador {operador.operador.nome} -{" "}
                            {currentDateTime}
                        </div>
                        <div>
                            <span>{observation.length}</span>
                            <span>/1500 caracteres</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.containerButton}>
                <button
                    className={
                        checkButtonSubmit
                            ? styles.buttonSearchBlock
                            : styles.buttonSearch
                    }
                    type="submit"
                    onClick={checkButtonSubmit ? () => {} : () => submitData()}
                    disabled={checkButtonSubmit ? true : false}
                >
                    Abrir ocorrência
                </button>
            </div>
        </div>
    );
}
