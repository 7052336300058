import React from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import btedit from "./../../../assets/images/bt-edit.png";
import styles from "./styles.module.scss";
import { DateBR } from "./../../../utils/date/index";

export function ListTable({ currentItems }) {
    const navigate = useNavigate();

    const getColorStatus = (status) => {
        switch (status) {
            case "Finalizada":
                return "#95C11F";
            case "Análise":
                return "#F9B000";
            case "Enviada":
                return "#0064B4";
            case "Cancelada":
                return "#E60E19";
            default:
                return "#656A6E";
        }
    };

    const getStatus = (status) => {
        switch (status) {
            case "Finalizada":
                return status;
            case "Análise":
                return "Em análise";
            case "Enviada":
                return "Aberta";
            default:
                return status;
        }
    };

    const handleNewPage = (id) => {
        navigate(
            `/ocorrencias/edit?id=${id}`,
        );
    }

    return (
        <div className="table-responsive">
            <table className={`table align-middle ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Situação</th>
                        <th>Cód.</th>
                        <th>Solicitante</th>
                        <th>Tipo de ocorrência</th>
                        <th>Serviço</th>
                        <th>Data do serviço</th>
                        <th>Nome do cliente</th>
                        <th>Nome do profissional</th>
                        <th>Unidade</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody data-test="table-body">
                    {currentItems.length > 0 ? currentItems.map((val, idx) => {
                        const colorTr = idx % 2 == 0 ? styles.colorTrOne : styles.colorTrTwo;
                        
                        return (
                            <tr className={colorTr} key={val.id.toString()}>
                                <td>
                                    <div
                                        className={styles.boxColor}
                                        style={{
                                            backgroundColor: getColorStatus(
                                                val.status,
                                            ),
                                        }}
                                    >
                                        <span
                                            className={
                                                styles.pillBadgesText
                                            }
                                        >
                                            {getStatus(val.status)}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    {val.id}
                                </td>
                                <td>
                                    {val.solicitante}
                                </td>
                                <td>{val.ocorrenciaTipo.nome}</td>
                                <td>
                                    {val.tarefa.servico.servico.nome}
                                </td>
                                <td>{DateBR(val.tarefa.datas[0])}</td>
                                <td>
                                    {val.tarefa.cliente.nome}
                                </td>
                                <td>
                                    {val.tarefa.profissionais[0].nome}
                                </td>
                                <td>
                                    {val.unidade.nome}
                                </td>
                                <td>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {
                                            <div
                                                onClick={() => handleNewPage(val.id)}
                                            >
                                                <img src={btedit} alt="Editar" title="Editar" />
                                            </div>
                                        }
                                    </Stack>
                                </td>
                            </tr>
                        )
                    }) : <></>}
                </tbody>
            </table>
        </div>
    );
}