import { Button, Container, Grid, Rating, Typography } from "@mui/material";
import { format, getHours, getMinutes, parseISO } from "date-fns";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseURL } from "../../../api/index";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import DownloadModalPDF from "../../../components/download/pdf/modal";
import { RatingCustom } from "../../../components/rating";
import ProfissionaisSolicitadosTable from "../../../components/tables/solicitacao/tarefas/profissionaisSolicitadosTable";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import { tokenPage } from "../../../utils/constant";
import {
    CompareDateIfBefore,
    DateBR,
    dateStringToObject,
    someDays,
} from "../../../utils/date";
import CurrencyInput, { TelMaskRegex, rgmask } from "../../../utils/mask";
import {
    ModalConfirm,
    ModalConfirmOrNo,
    ModalJustificativa,
    ModalSimplesSemConfirmacao,
} from "./../../../components/modal/index";
import styles from "./styles.module.scss";

const AceitarSolicitacaoServicoPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const search = useLocation().search;
    //ID Solicitação/Task
    const idQuery = new URLSearchParams(search).get("id");
    //ID Profissional
    const idProfissional = new URLSearchParams(search).get("idProfissional");
    //Preview:  Profissional / Cliente
    const preview = new URLSearchParams(search).get("preview");
    //ShowTable:  Mostrar a tabela => Profissionais solicitados
    const showTableProf = new URLSearchParams(search).get("showtable");
    const showTable = showTableProf === tokenPage ? true : false;

    // Profissional
    const [profissional, setProfissional] = useState([]);
    const [solicitacao, setSolicitacao] = useState([]);
    const printRef = useRef();
    //Modal
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalRecusar, setOpenModalRecusar] = useState(false);
    const [openModalRecibo, setOpenModalRecibo] = useState(false);
    const [openModalCancelar, setOpenModalCancelar] = useState(false);
    const [openModalAvaliarCliente, setOpenModalAvaliarCliente] =
        useState(false);
    const [
        openModalVisualizarAvaliacaoCliente,
        setOpenModalVisualizarAvaliacaoCliente,
    ] = useState(false);
    // Modal avaliar profissional
    const [openModalAvaliarProfissional, setOpenModalAvaliarProfissional] =
        useState(false);

    const [ratingCliente, setRatingCliente] = useState(0);
    const [ratingInicial, setRatingInicial] = useState(0);
    const [ratingProfissional, setRatingProfissional] = useState(0);
    const [ratingInicialProfissional, setRatingInicialProfissional] =
        useState(0);
    const [cliente, setCliente] = useState({});
    const [fotoCliente, setFotoCliente] = useState();
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [dataCompliments, setDataCompliments] = useState([]);
    const [commentToProfessional, setCommentToProfessional] = useState("");

    const [complimentsItems, setComplimentsItems] = useState([]);
    const [fotoProfissional, setFotoProfissional] = useState("");
    const [valorFinalizar, setValorFinalizar] = useState(0);
    const [refreshPage, setRefreshPage] = useState(false);

    //Modal e funções finalizar serviço
    const [openModalFinalizarServico, setOpenModalFinalizarServico] =
        useState(false);
    const [
        openModalFinalizarServicoComRecibo,
        setOpenModalFinalizarServicoComRecibo,
    ] = useState(false);
    const [openModalVerRecibo, setOpenModalVerRecibo] = useState(false);
    const [checkFutureDate, setCheckFutureDate] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState("");
    const [currentDateServidor, setCurrentDateServidor] = useState("");

    const pdfExportComponent = useRef(null);
    const layoutSelection = {
        text: "A4",
        value: "size-a4",
    };

    useEffect(() => {
        setCurrentScreen(preview === "profissional" ? 2 : 1);
    }, []);

    const handleFinalizarServiço = () => {
        if (!solicitacao.valor) {
            setValorFinalizar(
                solicitacao.servico?.servicoValor
                    ? solicitacao.servico?.servicoValor
                          .toString()
                          .replace(".", ",")
                    : "",
            );
        } else {
            setValorFinalizar(solicitacao.valor.toString().replace(".", ","));
        }

        setOpenModalFinalizarServico(false);
        setOpenModalFinalizarServicoComRecibo(true);
    };

    const handleSubmitRecipe = () => {
        if (!parseFloat(valorFinalizar.replace("R$", "")) > 0) {
            toast.error("Ops! O valor não pode ser menor que R$1,00", {
                toastId: "msg",
            });
            return;
        }

        let ENDPOINT = `/tarefa/${solicitacao.id}`;
        let ENDPOINT2 = `/tarefa/${solicitacao.id}/realizar`;

        let vTemp = valorFinalizar.replace(".", "");
        vTemp = vTemp.replace(",", ".");
        vTemp = vTemp.replace("R$", "");
        solicitacao.valor = vTemp;

        Api.put(ENDPOINT, solicitacao)
            .then((res) => {
                if (res.status === 200) {
                    setOpenModalConfirm(false);
                    if (solicitacao.status !== "Realizado") {
                        Api.post(ENDPOINT2)
                            .then((res2) => {
                                if (res2.status === 200) {
                                    setOpenModalConfirm(false);
                                }
                            })
                            .catch((error) => {
                                setOpenModalConfirm(false);
                                let message =
                                    typeof error.response !== "undefined"
                                        ? error.response.data.message
                                        : error.message;
                                toast.error("Ops! " + message.error, {
                                    toastId: "msg",
                                });
                            });
                    }
                    toast.success(
                        "Solicitação de serviço realizada com sucesso!",
                        {
                            toastId: "msg",
                            autoClose: 2000,
                            onClose: function () {
                                window.location.reload();
                            },
                        },
                    );
                }
            })
            .catch((error) => {
                setOpenModalConfirm(false);
                let message =
                    typeof error.response !== "undefined"
                        ? error.response.data.message
                        : error.message;
                toast.error("Ops! " + message.error, {
                    toastId: "msg",
                });
            })
            .finally(() => {
                //navigate("/resetar?cpf=" + cpf);
            });

        setOpenModalFinalizarServicoComRecibo(false);
    };

    const handleCancelarFinalizarServico = () => {
        if (solicitacao.status == "Não Realizado") {
        }
        let ENDPOINT = `/tarefa/${solicitacao.id}/profissional/${idProfissional}/nao-realizar`;
        // Endpoint
        Api.post(ENDPOINT, solicitacao)
            .then((res) => {
                if (res.status === 200) {
                    setOpenModalConfirm(false);
                    toast.success(
                        "Solicitação de serviço não realizada com sucesso!",
                        {
                            toastId: "msg",
                        },
                    );
                    window.location.reload(false);
                }
            })
            .catch((error) => {
                setOpenModalConfirm(false);
                let message =
                    typeof error.response !== "undefined"
                        ? error.response.data.message
                        : error.message;
                toast.error("Ops! " + message.error, {
                    toastId: "msg",
                });
            });

        setOpenModalFinalizarServico(false);
    };

    const addSelectedCompliments = (resultData) => {
        const checkDate = complimentsItems.find(
            (val) => val.id === resultData.id,
        );

        if (checkDate?.id) {
            const removeDate = complimentsItems.filter(
                (val) => val.id !== resultData.id,
            );

            setComplimentsItems(removeDate);
        } else {
            setComplimentsItems([...complimentsItems, resultData]);
        }
    };

    // Get
    useEffect(() => {
        let urlGetCliente = "/cliente/";
        Api.get("/tarefa/" + idQuery)
            .then((res) => {
                if (res.status === 200) {
                    setSolicitacao(res.data);
                    setProfissional(res.data.profissionais);

                    Api.get("/system/date").then((resData) => {
                        if (resData.status === 200) {
                            const todayDate = dateStringToObject(
                                resData.data.hoursDate,
                                "YYYY-MM-DD H:mm",
                            );

                            setCurrentDateServidor(todayDate);

                            setCurrentDateTime(resData.data);
                            const hoursBr = resData.data.hoursBr + ":00";
                            const serverDateTime = moment(
                                `${resData.data.date} ${hoursBr}`,
                            );
                            const dateSol = moment(
                                `${res.data.datas[0]} ${res.data.hora}`,
                            );
                            if (serverDateTime.diff(dateSol, "days") >= 0) {
                                setCheckFutureDate(true);
                            }
                        }
                    });

                    if (res.data.status === "Avaliado") {
                        setCommentToProfessional(
                            res.data?.profissionalAvaliacao.comentario ?? "",
                        );
                        setComplimentsItems(
                            res.data?.profissionalAvaliacao.elogios.length > 0
                                ? res.data?.profissionalAvaliacao.elogios
                                : [],
                        );
                        setRatingProfissional(
                            res.data?.profissionalAvaliacao?.nota ?? 0,
                        );
                        setRatingInicialProfissional(
                            res.data?.profissionalAvaliacao.nota,
                        );
                    }

                    let baseURL = BaseURL;

                    Api.get(urlGetCliente + res.data?.clienteId)
                        .then((res2) => {
                            setCliente(res2.data.usuario?.foto);
                            setFotoCliente(
                                baseURL +
                                    "api/usuario/security/" +
                                    res2.data.usuario?.foto +
                                    "?token=" +
                                    token,
                            );
                            let avaliacaoDefinitiva = 0;
                            let ava = res2.data?.avaliacoes?.map((item) => {
                                if (item.tarefaId == idQuery)
                                    avaliacaoDefinitiva = item.nota;
                            });
                            setRatingCliente(avaliacaoDefinitiva);
                        })
                        .catch((error) => {
                            // console.log(error);
                        });

                    //get Compliments
                    Api.get("profissional/elogios").then((resCompliments) => {
                        setDataCompliments(resCompliments.data);
                    });

                    //fetch professional info
                    Api.get(
                        "/profissional/" + res.data.profissionais[0]?.id,
                    ).then((resProfessional) => {
                        if (resProfessional.data.usuario?.foto) {
                            setFotoProfissional(
                                baseURL +
                                    "api/usuario/security/" +
                                    resProfessional.data.usuario?.foto +
                                    "?token=" +
                                    token,
                            );
                        }
                    });
                }
            })
            .catch((_) => {
                toast.error("Ops! Aconteceu algum problema.", {
                    toastId: "msg",
                });
            });
    }, [refreshPage]);

    /**
     * Check Day/Hour
     * Mesma lógica do mobile, exatamente antes do horário agendado. Por exemplo: se ta marcado para 14: 00 a partir das 14h já some a opção
     * @returns
     */
    const checkDayHourValid = (is24h = false) => {
        if (solicitacao.datas) {
            let todayDateTeste = dateStringToObject(
                new Date(),
                "YYYY-MM-DD H:mm",
            );
            const todayDate = currentDateServidor;
            let myDate = dateStringToObject(
                solicitacao.datas[0] + solicitacao.hora,
                "YYYY-MM-DD H:mm",
            );
            //Mesmo Dia: Hoje
            let isSomeDay = someDays(todayDate, myDate);
            //Depois de Hoje: Futuro
            let isDayAfterToday = CompareDateIfBefore(todayDate, myDate);
            //Hora Atual é menor que a hora marcada?
            let myHour = getHours(parseISO(myDate));
            let myMin = getMinutes(parseISO(myDate));
            let todayHour = getHours(parseISO(todayDate));
            let todayMin = getMinutes(parseISO(todayDate));

            console.log("DEBUG DATE: new Date() ===> : ", todayDateTeste);
            //Mesmo dia: menos de 24h
            if (is24h) {
                if (isSomeDay) {
                    return true;
                } else {
                    return false;
                }
            }
            //Futuro
            if (isDayAfterToday) {
                return true;
            }

            //Mesmo dias, mas antes da hora
            //console.log(isSomeDay, todayDate, myDate);
            if (isSomeDay) {
                if (todayHour == myHour && todayMin < myMin) {
                    return true;
                }
                if (todayHour <= myHour) {
                    return true;
                }
            }
            return false;
        }
        return false;
    };

    const handleAvaliarCliente = () => {
        let corpo = {
            nota: ratingCliente,
            profissionalId: solicitacao.profissionalId,
            tarefaId: solicitacao.id,
            clienteId: solicitacao.clienteId,
        };

        Api.post(
            "/cliente/" +
                corpo.clienteId +
                "/tarefa/" +
                corpo.tarefaId +
                "/avaliar",
            corpo,
        )
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Cliente avaliado com sucesso!", {
                        toastId: "success",
                        autoClose: 1500,
                        onClose: function () {
                            window.location.reload();
                        },
                    });
                    setOpenModalAvaliarCliente(false);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("Ops! Aconteceu algum problema.", {
                    toastId: "msg",
                });
            });
    };

    function getDifference(array1, array2) {
        return array1.filter((object1) => {
            return !array2.some((object2) => {
                return (
                    object1.nota === object2.nota &&
                    object1.comentario === object2.comentario &&
                    JSON.stringify(object1.elogios).length ===
                        JSON.stringify(object2.elogios).length
                );
            });
        });
    }

    const profAvalRemot = [
        {
            nota: solicitacao?.profissionalAvaliacao?.nota ?? 0,
            comentario: solicitacao?.profissionalAvaliacao?.comentario ?? "",
            elogios:
                solicitacao?.profissionalAvaliacao?.elogios.length > 0
                    ? solicitacao?.profissionalAvaliacao?.elogios
                    : [],
        },
    ];

    const profAvalLocal = [
        {
            nota: ratingProfissional,
            comentario: commentToProfessional,
            elogios: complimentsItems,
        },
    ];

    const difference = getDifference(profAvalRemot, profAvalLocal);

    const checkUpdate01 =
        solicitacao.status === "Avaliado"
            ? difference.length
            : ratingInicialProfissional == 0 ||
              ratingInicialProfissional == null
            ? false
            : true;

    const checkUpdate = checkUpdate01 && ratingProfissional > 0 ? false : true;

    const handleAvaliarProfissional = () => {
        let corpo = {
            nota: ratingProfissional,
            profissionalId: solicitacao.profissionalId,
            tarefaId: solicitacao.id,
            clienteId: solicitacao.clienteId,
            data: currentDateTime.date,
            comentario: commentToProfessional,
            elogios: ratingProfissional > 3 ? complimentsItems : [],
        };

        if (solicitacao.status === "Realizado") {
            Api.post("tarefa/" + corpo.tarefaId + "/avaliar", corpo)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success("Profissional avaliado com sucesso!", {
                            toastId: "success",
                            autoClose: 1500,
                            onClose: function () {
                                window.location.reload();
                            },
                        });
                        setOpenModalAvaliarProfissional(false);
                        setOpenModalRecibo(false);
                    }
                })
                .catch((error) => {
                    toast.error("Ops! Aconteceu algum problema.", {
                        toastId: "msg",
                    });
                });
        } else {
            Api.put("tarefa/" + corpo.tarefaId + "/avaliar", corpo)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(
                            "Edição de avaliação do profissional realizada com sucesso!",
                            {
                                toastId: "success",
                                autoClose: 1500,
                                onClose: function () {
                                    window.location.reload();
                                },
                            },
                        );
                        setOpenModalAvaliarProfissional(false);
                        setOpenModalRecibo(false);
                    }
                })
                .catch((error) => {
                    toast.error("Ops! Aconteceu algum problema.", {
                        toastId: "msg",
                    });
                });
        }
    };

    const handleVerRecibo = () => {
        setOpenModalRecibo(false);
        setOpenModalAvaliarProfissional(true);
    };
    //
    // Aceitar
    const handleAceitar = () => {
        //Add ID Professional

        let ENDPOINT = `/tarefa/${solicitacao.id}/profissional/${idProfissional}/confirmar`;
        // Endpoint
        Api.post(ENDPOINT, solicitacao)
            .then((res) => {
                if (res.status === 200) {
                    setOpenModalConfirm(false);
                    toast.success(
                        "Solicitação de serviço aceito com sucesso!",
                        {
                            toastId: "msg",
                            autoClose: 1000,
                            onClose: function () {
                                window.location.reload();
                            },
                        },
                    );
                }
            })
            .catch((error) => {
                setOpenModalConfirm(false);
                const checkErro = error;
                toast.error(
                    "Ops! Aconteceu algum problema:" +
                        checkErro.response.data.message?.error,
                    {
                        toastId: "msg",
                    },
                );
            });
    };
    // Recusar
    const handleRecusar = () => {
        let ENDPOINT = `/tarefa/${solicitacao.id}/profissional/${idProfissional}/recusar`;
        Api.delete(ENDPOINT, solicitacao)
            .then((res) => {
                if (res.status === 200) {
                    setOpenModalRecusar(false);
                    toast.success(
                        "Solicitação de serviço recusado com sucesso!",
                        {
                            toastId: "msg",
                            autoClose: 1000,
                            onClose: function () {
                                window.location.reload();
                            },
                        },
                    );
                }
            })
            .catch((err) => {
                setOpenModalRecusar(false);
                console.error(err);
                toast.error(
                    "Ops! Aconteceu algum problema:" + err.message?.error,
                    {
                        toastId: "msg",
                    },
                );
            });
    };

    // Cancelar
    const handleCancelar = () => {
        let ENDPOINT = `/tarefa/${solicitacao.id}/cliente/${solicitacao.clienteId}/cancelar`;
        Api.delete(ENDPOINT, solicitacao)
            .then((res) => {
                if (res.status === 200) {
                    setOpenModalCancelar(false);
                    toast.success(
                        "Solicitação de serviço cancelado com sucesso!",
                        {
                            toastId: "msg",
                            autoClose: 1000,
                            onClose: function () {
                                window.location.reload();
                            },
                        },
                    );
                }
            })
            .catch((err) => {
                setOpenModalCancelar(false);
                toast.error(
                    "Ops! Aconteceu algum problema:" + err.message?.error,
                    {
                        toastId: "msg",
                    },
                );
            });
    };

    if (Object.values(solicitacao).length <= 0) {
        return <Title title="Carregando..." />;
    }

    let customTime = "";
    if (solicitacao.hora) {
        const exactDate = `${currentDateTime.date} ${solicitacao.hora}`;
        const exactDateTimestamp = new Date(exactDate).getTime();
        if (exactDateTimestamp) {
            customTime = format(exactDateTimestamp, "H:mm");
        }
    }

    const chooseEvaluation = (value) => {
        setRatingCliente(value);
        setRatingInicial(value);
    };

    return (
        <Grid>
            <BreadCrumb
                data={{
                    category: "",
                    page:
                        preview === "profissional" ? "Profissional" : "Cliente",
                    menu: {
                        item: "Usuários",
                        link: "",
                    },
                }}
            />
            <Title title="Solicitações de Serviços" />
            <Container className={styles.formulario}>
                <form className={styles.formularioItem}>
                    <div
                        className={`d-flex justify-content-between ${styles.marginBottom}`}
                    >
                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Status"
                                name="status"
                                id="status"
                                value={solicitacao.status}
                                disabled
                            />
                        </Form.Group>

                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Número da solicitação</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Número da solicitação"
                                name="numerodasolicitacao"
                                id="numerodasolicitacao"
                                value={String(solicitacao.id).padStart(10, 0)}
                                disabled
                            />
                        </Form.Group>
                    </div>

                    <div
                        className={`d-flex justify-content-between ${styles.marginBottom}`}
                    >
                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Tipo de solicitação</Form.Label>
                            <Form.Control
                                type="text"
                                value={solicitacao.tipo}
                                disabled
                            />
                        </Form.Group>

                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Serviço</Form.Label>
                            <Form.Control
                                type="text"
                                value={solicitacao.servico?.servico?.nome}
                                disabled
                            />
                        </Form.Group>
                    </div>

                    <div className="d-flex justify-content-between">
                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Data</Form.Label>
                            <Form.Control
                                type="text"
                                value={DateBR(solicitacao.datas[0])}
                                disabled
                            />
                        </Form.Group>

                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Hora</Form.Label>
                            <Form.Control
                                type="text"
                                value={customTime}
                                disabled
                            />
                        </Form.Group>
                    </div>
                    <hr className={styles.spaceHR} />
                    <div
                        className={`d-flex justify-content-between ${styles.marginBottom}`}
                    >
                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Cliente</Form.Label>
                            <Form.Control
                                type="text"
                                value={solicitacao.cliente?.nome}
                                disabled
                            />
                        </Form.Group>

                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control
                                type="text"
                                value={TelMaskRegex(
                                    solicitacao?.cliente?.telefoneCelular ?? "",
                                )}
                                disabled
                            />
                        </Form.Group>
                    </div>

                    <div className="d-flex justify-content-between">
                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Profissional</Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    // (status !== "A Confirmar") || (status.status === "A Confirmar" && prof.length == 1)
                                    (solicitacao.status === "A Confirmar" &&
                                        profissional.length == 1) ||
                                    solicitacao.status !== "A Confirmar"
                                        ? profissional[0]?.nome
                                        : ""
                                }
                                disabled
                            />
                        </Form.Group>

                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    (solicitacao.status === "A Confirmar" &&
                                        profissional.length == 1) ||
                                    solicitacao.status !== "A Confirmar"
                                        ? TelMaskRegex(
                                              profissional[0]
                                                  ?.telefoneCelular ?? "",
                                          )
                                        : ""
                                }
                                disabled
                            />
                        </Form.Group>
                    </div>
                    <hr className={styles.spaceHR} />
                    <div
                        className={`d-flex justify-content-between ${styles.marginBottom}`}
                    >
                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>CEP</Form.Label>
                            <Form.Control
                                type="text"
                                value={solicitacao?.endereco?.cep}
                                disabled
                            />
                        </Form.Group>

                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Endereço</Form.Label>
                            <Form.Control
                                type="text"
                                value={solicitacao?.endereco?.logradouro}
                                disabled
                            />
                        </Form.Group>
                    </div>

                    <div
                        className={`d-flex justify-content-between ${styles.marginBottom}`}
                    >
                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Número</Form.Label>
                            <Form.Control
                                type="text"
                                value={solicitacao?.endereco?.numero}
                                disabled
                            />
                        </Form.Group>

                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Complemento</Form.Label>
                            <Form.Control
                                type="text"
                                value={solicitacao?.endereco?.complemento}
                                disabled
                            />
                        </Form.Group>
                    </div>

                    <div className="d-flex justify-content-between">
                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Bairro</Form.Label>
                            <select
                                type="text"
                                value={solicitacao?.endereco?.bairro}
                                disabled
                                className="form-select"
                            >
                                <option value="true" key="1">
                                    {solicitacao?.endereco?.bairro}
                                </option>
                            </select>
                        </Form.Group>

                        <Form.Group style={{ width: "49%" }}>
                            <Form.Label>Cidade</Form.Label>
                            <select
                                type="text"
                                value={solicitacao?.endereco?.cidade}
                                disabled
                                className="form-select"
                            >
                                <option value="true" key="1">
                                    {solicitacao?.endereco?.cidade}
                                </option>
                            </select>
                        </Form.Group>
                    </div>

                    <div className={`d-flex justify-content-end`}>
                        <Form.Group className={`my-5`}>
                            {preview == "profissional" &&
                            (solicitacao.status == "Não Realizado" ||
                                solicitacao.status == "Realizado") ? (
                                <>
                                    <span
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpenModalFinalizarServico(true);
                                        }}
                                        className="link-blue"
                                    >
                                        Editar finalização
                                    </span>
                                </>
                            ) : (
                                <></>
                            )}

                            {/* Profissional */}
                            {solicitacao.status == "A Confirmar" &&
                            preview == "profissional" &&
                            checkDayHourValid() ? (
                                <>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpenModalRecusar(true);
                                        }}
                                        className="button-cancelar"
                                    >
                                        Recusar serviço
                                    </button>
                                    <button
                                        className="button-submeter"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpenModalConfirm(true);
                                        }}
                                    >
                                        Aceitar serviço
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                            {/* Cliente */}
                            {solicitacao.status == "A Confirmar" &&
                            preview === "cliente" &&
                            checkDayHourValid() ? (
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setOpenModalCancelar(true);
                                    }}
                                    className="button-cancelar"
                                >
                                    Cancelar serviço
                                </button>
                            ) : (
                                <></>
                            )}
                            {/* Ambos */}
                            {solicitacao.status == "Confirmado" &&
                            checkDayHourValid() ? (
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setOpenModalCancelar(true);
                                    }}
                                    className="button-cancelar"
                                >
                                    Cancelar serviço
                                </button>
                            ) : (
                                <></>
                            )}
                            {solicitacao.status == "Avaliado" ||
                            solicitacao.status == "Realizado" ? (
                                <>
                                    {preview == "cliente" ? (
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                    solicitacao.status !==
                                                    "Realizado"
                                                ) {
                                                    setOpenModalAvaliarProfissional(
                                                        true,
                                                    );
                                                } else {
                                                    setOpenModalAvaliarProfissional(
                                                        true,
                                                    );
                                                }
                                            }}
                                            className={`${styles.colorBTN_BLUE}`}
                                        >
                                            {solicitacao.status === "Realizado"
                                                ? "Avaliar Profissional"
                                                : "Ver Avaliação"}
                                        </button>
                                    ) : (
                                        <></>
                                    )}

                                    {preview == "profissional" ? (
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                    solicitacao.flagAvaliada ===
                                                    true
                                                ) {
                                                    setOpenModalVisualizarAvaliacaoCliente(
                                                        true,
                                                    );
                                                } else {
                                                    setOpenModalAvaliarCliente(
                                                        true,
                                                    );
                                                }
                                            }}
                                            className="button-submeter"
                                        >
                                            {solicitacao.flagAvaliada === true
                                                ? "Ver Avaliação"
                                                : "Avaliar Cliente"}
                                        </button>
                                    ) : (
                                        <></>
                                    )}

                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (
                                                solicitacao.status ===
                                                    "Realizado" &&
                                                preview === "cliente"
                                            ) {
                                                setOpenModalRecibo(true);
                                            } else {
                                                setOpenModalVerRecibo(true);
                                            }
                                        }}
                                        className={`${styles.colorBTN_WHITE}`}
                                    >
                                        Ver recibo
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                            {/* {serviço confirmado, exibir o botão finalizar} */}
                            {solicitacao.status === "Confirmado" &&
                            preview == "profissional" &&
                            checkFutureDate ? (
                                <>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpenModalFinalizarServico(true);
                                        }}
                                        className="button-submeter"
                                    >
                                        Finalizar serviço
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </Form.Group>
                    </div>
                </form>
            </Container>

            {showTable && solicitacao.status === "A Confirmar" && (
                <Container
                    maxWidth="xl"
                    className={`${styles.formularioBottom} mb-7`}
                >
                    <div className={styles.titleProfissionaosSolicitados}>
                        Profissionais solicitados
                    </div>
                    <div className={styles.boxProfessionalTable}>
                        <ProfissionaisSolicitadosTable
                            profissionais={solicitacao.profissionais}
                        />
                    </div>
                </Container>
            )}

            {/* Modal Salvar Alterações */}
            <ModalConfirm
                id="aceitar"
                title="Aceitar Serviço"
                description="Tem certeza que deseja aceitar este serviço?"
                openModal={openModalConfirm}
                setOpenModal={setOpenModalConfirm}
                onClick={() => handleAceitar()}
                color="blue"
                labelConfirm="Aceitar"
            />
            <ModalConfirm
                id="recusar"
                title="Recusar Serviço"
                description="Tem certeza que deseja recusar este serviço?"
                openModal={openModalRecusar}
                setOpenModal={setOpenModalRecusar}
                onClick={() => handleRecusar()}
                labelCancel="Não"
                labelConfirm="Sim"
            />
            <ModalConfirmOrNo
                id="finalizarServico"
                title="Finalizar Serviço"
                description="O serviço foi finalizado para o cliente?"
                color="blue"
                openModal={openModalFinalizarServico}
                setOpenModal={setOpenModalFinalizarServico}
                onClick={() => handleFinalizarServiço()}
                handleCancel={() => handleCancelarFinalizarServico()}
                labelCancel="Não"
                labelConfirm="Sim"
            />

            <ModalConfirm
                id="cancelar"
                title="Cancelar Serviço"
                description={
                    checkDayHourValid(true) && preview == "cliente"
                        ? "Este cancelamento estará sujeito ao pagamento do transporte, caso o profissional já tenha se deslocado para o local de realização do serviço. Tem certeza que deseja cancelar?"
                        : checkDayHourValid(true) && preview == "profissional"
                        ? "Este cancelamento irá gerar uma ocorrência. Deseja realmente cancelar o serviço?"
                        : "Tem certeza que deseja cancelar este serviço?"
                }
                openModal={openModalCancelar}
                setOpenModal={setOpenModalCancelar}
                onClick={() => handleCancelar()}
                labelCancel="Não"
                labelConfirm="Sim"
            />

            <ModalJustificativa
                styles={{
                    minHeight: "578px",
                    width: "558px",
                }}
                title="Avalie o Cliente"
                description=""
                openModal={openModalAvaliarCliente}
                setOpenModal={setOpenModalAvaliarCliente}
                onClick={() => handleAvaliarCliente()}
                color="blue"
                disabled={ratingInicial == 0}
                labelConfirm="Avaliar"
            >
                <Container
                    className={`justify-content-end ${styles.containerModal}`}
                    maxWidth="xl"
                >
                    <div
                        className={`d-flex justify-content-center align-items-center container ${styles.cardProfImage}`}
                    >
                        {fotoCliente?.length > 0 ? (
                            <img
                                src={fotoCliente}
                                className={styles.profileImage}
                            />
                        ) : (
                            <div className={styles.notPhoto} />
                        )}
                    </div>

                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        <span className={styles.subtitleName}>
                            {solicitacao.cliente?.nome}
                        </span>
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        <span className={styles.subtitleExperience}>
                            Como foi a experiência?
                        </span>
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        <RatingCustom
                            disabled={false}
                            evaluation={ratingInicial}
                            chooseEvaluation={chooseEvaluation}
                        />
                    </Form.Group>
                </Container>
            </ModalJustificativa>

            <ModalSimplesSemConfirmacao
                styles={{
                    minHeight: "578px",
                    width: "558px",
                }}
                title="Avalie o Cliente"
                description=""
                openModal={openModalVisualizarAvaliacaoCliente}
                setOpenModal={setOpenModalVisualizarAvaliacaoCliente}
                color="blue"
                labelConfirm="Avaliar"
                labelCancel="Fechar"
            >
                <Container
                    className={`justify-content-end ${styles.containerModal}`}
                >
                    <div
                        className={`d-flex justify-content-center align-items-center container ${styles.cardProfImage}`}
                    >
                        {fotoCliente?.length > 0 ? (
                            <img
                                src={fotoCliente}
                                className={styles.profileImage}
                            />
                        ) : (
                            <div className={styles.notPhoto} />
                        )}
                    </div>
                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        <span className={styles.subtitleName}>
                            {solicitacao.cliente?.nome}
                        </span>
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        <span className={styles.subtitleExperience}>
                            Como foi a experiência?
                        </span>
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        <RatingCustom
                            disabled={true}
                            evaluation={ratingCliente}
                            chooseEvaluation={setRatingCliente}
                        />
                    </Form.Group>
                </Container>
            </ModalSimplesSemConfirmacao>

            <ModalJustificativa
                styles={{
                    minHeight: "578px",
                    width: "558px",
                }}
                title="Avalie o Profissional"
                description=""
                openModal={openModalAvaliarProfissional}
                setOpenModal={setOpenModalAvaliarProfissional}
                onClick={() => handleAvaliarProfissional()}
                color="blue"
                disabled={checkUpdate}
                labelConfirm={
                    solicitacao.status === "Realizado"
                        ? "Avaliar"
                        : "Salvar edição"
                }
            >
                <Container
                    className={`justify-content-end ${styles.containerModal}`}
                    maxWidth="xl"
                >
                    <div
                        className={`d-flex justify-content-center align-items-center container ${styles.cardProfImage}`}
                    >
                        {fotoProfissional.length > 0 ? (
                            <img
                                src={fotoProfissional}
                                className={styles.profileImage}
                            />
                        ) : (
                            <div className={styles.notPhoto} />
                        )}
                    </div>

                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        <Typography
                            sx={{
                                mb: 0.5,
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "24px",
                                lineHeight: "25px",
                                display: "flex",
                                alignItems: "center",
                                color: "#343B40",
                                marginBottom: "40px",
                                wordBreak: "break-all",
                            }}
                        >
                            {solicitacao.profissionais[0]?.nome}
                        </Typography>
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        <Typography
                            sx={{
                                mb: 0.5,
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "16px",
                                lineHeight: "17px",
                                display: "flex",
                                alignItems: "center",
                                color: "#656A6E",
                                marginBottom: "26px",
                            }}
                        >
                            Como foi a experiência?
                        </Typography>
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        <Rating
                            name="avaliarProfissional"
                            value={ratingInicialProfissional}
                            defaultValue={1}
                            onChange={(event, newValue) => {
                                setRatingProfissional(newValue);
                                setRatingInicialProfissional(newValue);
                            }}
                            className={styles.ratingStars}
                        />
                    </Form.Group>

                    <Form.Group className="d-flex justify-content-center align-items-center container">
                        {ratingProfissional >= 4 ? (
                            <>
                                <div className={styles.complimentsBox}>
                                    {dataCompliments?.length > 0 &&
                                        dataCompliments?.map((val) => {
                                            const dataSelected =
                                                complimentsItems.filter(
                                                    (value) =>
                                                        value.id === val.id,
                                                );

                                            return (
                                                <div
                                                    className={
                                                        dataSelected.length > 0
                                                            ? styles.complimentButtonPressed
                                                            : styles.complimentButton
                                                    }
                                                >
                                                    <Button
                                                        key={val.id.toString()}
                                                        onClick={() =>
                                                            addSelectedCompliments(
                                                                val,
                                                            )
                                                        }
                                                        size="large"
                                                    >
                                                        <span
                                                            className={
                                                                dataSelected.length >
                                                                0
                                                                    ? styles.complimentText
                                                                    : styles.complimentTextBlue
                                                            }
                                                            status={
                                                                dataSelected.length >
                                                                0
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            {val.nome}
                                                        </span>
                                                    </Button>
                                                </div>
                                            );
                                        })}
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </Form.Group>

                    <Form.Group
                        className={`d-flex justify-content-center align-items-center container ${styles.cardComment}`}
                    >
                        <div className={styles.boxCommentProfessional}>
                            <div className={styles.boxCommentProfessionalText}>
                                <Form.Label>Escreva um comentário</Form.Label>
                            </div>
                            <Form.Control
                                className={styles.textAreaCommentProfessional}
                                as="textarea"
                                rows={3}
                                name="descricao"
                                id="descricao"
                                value={commentToProfessional}
                                maxLength={200}
                                onChange={(e) =>
                                    setCommentToProfessional(e.target.value)
                                }
                            />
                        </div>
                    </Form.Group>
                </Container>
            </ModalJustificativa>
            <ModalConfirm
                id="verRecibo"
                title="Avalie o serviço"
                description="Para acessar o recibo, é necessário avaliar o profissional. Selecione a opção que deseja para continuar"
                openModal={openModalRecibo}
                setOpenModal={setOpenModalRecibo}
                onClick={() => handleVerRecibo()}
                color="blue"
                labelConfirm="Avaliar"
                labelXSair="nao"
            />

            {/* {modal definir valor recibo} */}
            <ModalJustificativa
                styles={{
                    minHeight: "578px",
                    width: "558px",
                }}
                title="Recibo"
                description=""
                openModal={openModalFinalizarServicoComRecibo}
                setOpenModal={setOpenModalFinalizarServicoComRecibo}
                onClick={() => handleSubmitRecipe()}
                color="blue"
                labelConfirm="Confirmar"
            >
                {/* <Container className="recipe-container"> */}
                <Container
                    className={`justify-content-end ${styles.recipeContainer}`}
                    maxWidth="xl"
                >
                    <div className={styles.containerModal}>
                        <div className={styles.titleRecipe}>Cliente</div>
                        <div className={styles.subTitleRecipe}>Nome</div>
                        <div className={styles.subInfoRecipe}>
                            {solicitacao?.cliente?.nome}
                        </div>
                        <div className={styles.subTitleRecipe}>Endereço</div>
                        <div className={styles.subInfoRecipe}>
                            {solicitacao?.cliente?.enderecos[0]?.logradouro}
                        </div>
                        <hr />
                        <div className={styles.titleRecipe}>Profissional</div>
                        <div className={styles.subTitleRecipe}>Nome</div>
                        <div className={styles.subInfoRecipe}>
                            {profissional[0]?.nome}
                        </div>
                        <div className={styles.subTitleRecipe}>RG</div>
                        <div className={styles.subInfoRecipe}>
                            {profissional[0]?.rg?.length > 0
                                ? rgmask(profissional[0]?.rg)
                                : ""}
                        </div>
                        <Container className={styles.recipeInnerContainer}>
                            <div className={styles.titleRecipe}>Serviço</div>
                            <div className={styles.subTitleRecipe}>Serviço</div>
                            <div className={styles.subInfoRecipe}>
                                {solicitacao?.servico?.servico?.nome}
                            </div>
                            <div className={styles.subTitleRecipe}>
                                Tipo do serviço
                            </div>
                            <div className={styles.subInfoRecipe}>
                                {solicitacao?.servicosTipos[0]?.nome}
                            </div>
                            <div className={styles.subTitleRecipe}>Local</div>
                            <div className={styles.subInfoRecipe}>
                                {solicitacao?.endereco?.logradouro}
                            </div>
                            <div className={styles.subTitleRecipe}>
                                Data e hora
                            </div>
                            <div className={styles.subInfoRecipe}>
                                {solicitacao?.datas[0]?.length > 0
                                    ? DateBR(solicitacao?.datas[0])
                                    : ""}{" "}
                                : {solicitacao?.hora?.slice(0, -3)}
                            </div>
                            <div className={styles.subTitleRecipe}>Valor</div>
                            <div className={styles.subInfoRecipe}>
                                <CurrencyInput
                                    type="text"
                                    name="valor"
                                    id="valor"
                                    className={styles.recipeValueText}
                                    value={valorFinalizar}
                                    onChange={(e) => {
                                        setValorFinalizar(e.target.value);
                                    }}
                                />
                            </div>
                        </Container>
                    </div>
                </Container>
            </ModalJustificativa>

            <DownloadModalPDF
                solicitacao={solicitacao}
                profissional={profissional}
                openModalVerRecibo={openModalVerRecibo}
                setOpenModalVerRecibo={setOpenModalVerRecibo}
            />
        </Grid>
    );
};

export default AceitarSolicitacaoServicoPage;
