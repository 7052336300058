import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { ServiceContext } from "../../../../contexts/service";
import { Api } from "../../../../providers";
import ResultNotFound from "../../../resultNotFound";
import { ListTable } from "./listTable";
import styles from "./styles.module.scss";

const RquestTable = () => {
    const {
        requests,
        setRequests,
        loading,
        setLoading,
        totalPages,
        setTotalPages,
        paramsFilter,
    } = useContext(ServiceContext);
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);

    let mounted = true;

    useEffect(() => {
        Api.get(`/tarefa/pagination/0/10`)
            .then((res) => {
                if (mounted) {
                    setRequests(res.data.content);
                    setTotalPages(res.data.totalPages);
                    setLoading(false);
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoading(false);
                }
            });
        return () => {
            mounted = false;
            setRequests([]);
        };
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [paramsFilter]);

    const nextPage = (page = 0) => {
        setLoading(true);
        const urlData = `/tarefa/pagination/${page}/10/`;
        const params =
            Object.values(paramsFilter).length > 0 ? paramsFilter : "";
        Api.get(urlData, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                setRequests(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            })
            .catch((_) => {
                setLoading(false);
            });
    };
    const currentItems = requests;
    const pageCountData = totalPages;

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    const handleNewPage = () => {
        navigate(`/solicitacao/add`);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={styles.textNewOccurrence}>
                <span onClick={() => handleNewPage()}>
                    Criar nova solicitação
                </span>
            </div>

            {currentItems.length > 0 && (
                <ListTable currentItems={currentItems} />
            )}

            {currentItems.length <= 0 && (
                <ResultNotFound style={{ marginBottom: 20 }} />
            )}

            <div className={styles.reactPaginate}>
                <ReactPaginate
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCountData}
                    previousLabel="<"
                    nextLabel=">"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName={styles.pagination}
                    pageClassName={styles.pageItem}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={styles.pageLink}
                    nextClassName={styles.pageItem}
                    nextLinkClassName={styles.pageLink}
                    activeClassName={"active"}
                    forcePage={currentPage - 1}
                    disabledClassName={styles.disabled}
                />
            </div>
        </>
    );
};

export default RquestTable;
