import React from "react";
import { Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import btedit from "./../../../assets/images/bt-edit.png";
import styles from "./styles.module.scss";
import { CpfCnpjMaskRegex, TelMaskRegex } from "../../../utils/mask";

export function ListTable({ currentItems }) {
    const navigate = useNavigate();

    const getColorStatus = (status) => {
        switch (status) {
            case "Ativo":
                return "#95C11F";
            case "Inativo":
                return "#E60E19";
            case "Excluído":
                return "#8D8F90";
            default:
                break;
        }
    };

    const handleNewPage = (id) => {
        navigate(
            `/cliente/editar?id=${id}`,
        );
    }

    const checkRemove = (val) => {
        if (val === "Excluído") {
            return "-"
        }
        return "";
    }

    return (
        <Grid item xs={6} sm={12}>
            <div className="table-responsive">
                <table className={`table align-middle ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>Situação</th>
                            <th>Nome</th>
                            <th>CPF/CNPJ</th>
                            <th>Telefone</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody data-test="table-body">
                        {currentItems.length > 0 ? currentItems.map((val, idx) => {
                            const colorTr = idx % 2 == 0 ? styles.colorTrOne : styles.colorTrTwo;
                            
                            return (
                                <tr className={colorTr} key={val.id.toString()}>
                                    <td className={styles.boxTd}>
                                        <div
                                            className={styles.boxColor}
                                            style={{
                                                backgroundColor: getColorStatus(
                                                    val.situacao,
                                                ),
                                            }}
                                        >
                                            <span
                                                className={
                                                    styles.pillBadgesText
                                                }
                                            >
                                                {val.situacao}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        {val.nome}
                                    </td>
                                    <td>
                                        {val.cpfCnpj ? CpfCnpjMaskRegex(val.cpfCnpj) : checkRemove(val.situacao)}
                                    </td>
                                    <td>{val.telefoneCelular ? TelMaskRegex(val.telefoneCelular) : checkRemove(val.situacao)}</td>
                                    <td>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                // display: 'flex',
                                                justifyContent: 'center',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                display: val.situacao === "Excluído" ? "none" : "block",
                                            }}
                                        >
                                            {
                                                <div
                                                    onClick={() => handleNewPage(val.id)}
                                                >
                                                    <img src={btedit} alt="Editar" title="Editar" />
                                                </div>
                                            }
                                        </Stack>
                                    </td>
                                </tr>
                            )
                        }) : <></>}
                    </tbody>
                </table>
            </div>
        </Grid>
    );
}