import { Grid } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseURL } from "../../../api";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import MyDatePicker from "../../../components/datepicker";
import { ModalLoading } from "../../../components/modal";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { ClientesProfissionaisProvider } from "../../../contexts/service";
import { useUnidades } from "../../../hooks/useScriptRef";
import { Api } from "../../../providers";
import { limitString } from "../../../utils/string";
import styles from "./styles.module.scss";

export default function RelatServicoCategoria() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const { setCurrentScreen } = useContext(AuthContext);
    const unidadesServer = useUnidades();

    const [loadingModal, setLoadingModal] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [descricao, setDescricao] = useState("");
    const [descricaoCancel, setDescricaoCancel] = useState("");
    const [textDescError, setTextDescError] = useState("Campo obrigatório");
    const [textDescErrorCancel, setTextDescErrorCancel] =
        useState("Campo obrigatório");
    const [statusButton, setStatusButton] = useState(true);
    const [statusButtonCancel, setStatusButtonCancel] = useState(true);
    const [currentDateTime, setCurrentDateTime] = useState("");
    const [timestamp, setTimestamp] = useState(0);
    const [statusOpenModal, setStatusOpenModal] = useState(false);
    const [score, setScore] = useState("Sim");
    const [updateData, setUpdateData] = useState(false);
    const [openModalCancelar, setOpenModalCancelar] = useState(false);

    const [categorias, setCategorias] = useState([]);
    const [categoriaId, setCategoriaId] = useState("");
    const [unidadeId, setUnidadeId] = useState("");
    const [servicos, setServicos] = useState([]);
    const [servicoId, setServicoId] = useState("");
    const [genero, setGenero] = useState([]);
    const [generoId, setGeneroId] = useState("");
    const [situacao, setSituacao] = useState([]);
    const [situacaoId, setSituacaoId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startDateError, setStartDateError] = useState("Campo obrigatório");
    const [endDateError, setEndDateError] = useState("Campo obrigatório");
    const [municipios, setMunicipios] = useState([]);
    const [municipioId, setMunicipioId] = useState("");
    const [bairros, setBairros] = useState([]);
    const [bairrosId, setBairrosId] = useState("");
    const [nomeBairro, setNomeBairro] = useState("");
    const [nomeCidade, setNomeCidade] = useState("");

    const token = localStorage.getItem("token");
    let mounted = true;

    //dataInicio
    useEffect(() => {
        const elementInic = document.getElementById("dataInicio");
        elementInic.setAttribute("placeholder", "Escolha uma data");
        elementInic.addEventListener("click", displayDateInic);

        const elementFim = document.getElementById("dataFim");
        elementFim.setAttribute("placeholder", "Escolha uma data");
        elementFim.addEventListener("click", displayDateFim);
    }, []);

    function displayDateInic() {
        const element = document.getElementById("dataInicio");
        element.setAttribute("placeholder", "Escolha uma data");
    }

    function displayDateFim() {
        const element = document.getElementById("dataFim");
        element.setAttribute("placeholder", "Escolha uma data");
    }

    useEffect(() => {
        setCurrentScreen(14);

        async function filterData() {
            setLoadingModal(true);
            try {
                await Promise.all([
                    Api.get("/categoria/").then((res) => {
                        if (mounted) {
                            const result = res.data.filter(
                                (data) => data.flagAtivo,
                            );
                            setCategorias(result);
                        }
                    }),
                    Api.get("/servico/").then((res) => {
                        if (res.status == 200 && mounted) {
                            const result = res.data.filter(
                                (data) => data.flagAtivo,
                            );
                            setServicos(result);
                        }
                    }),
                    Api.get(`/municipio`).then((res) => {
                        if (res.status == 200) {
                            setMunicipios(res.data);
                        }
                    }),
                    Api.get(`/bairro`).then((res) => {
                        if (res.status == 200) {
                            const uniqueArray = res.data.filter(
                                (obj, index, self) =>
                                    index ===
                                    self.findIndex(
                                        (t) => t.logradouro == obj.logradouro,
                                    ),
                            );
                            const totalBairro = [];
                            const unicData = uniqueArray
                                .map((value) => {
                                    totalBairro.push(value.logradouro ?? "");
                                    return {
                                        ...value,
                                    };
                                })
                                .filter((data) => data.logradouro != "");
                            setBairros(totalBairro);
                        }
                    }),
                    Api.get("/genero/").then((res) => {
                        if (res.status === 200) {
                            if (mounted) {
                                setGenero(res.data);
                            }
                        }
                    }),
                    Api.get("/profissional-situacao/").then((res) => {
                        if (mounted) {
                            setSituacao(res.data);
                        }
                    }),
                ]);
            } catch (error) {
                toast.error("Ops! Aconteceu algum problema com os filtros!", {
                    toastId: "msg",
                });
            } finally {
                setLoadingModal(false);
            }
        }
        filterData();
        return () => (mounted = false);
    }, []);

    const handleStartDate = (text) => {
        const checkDate01 = moment(text, "YYYY-MM-DD").valueOf();
        const checkDate02 = moment(endDate, "YYYY-MM-DD").valueOf();
        const subtractDate = moment(endDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        const checkDateStart =
            moment(text, "YYYY-MM-DD").valueOf() > 0 ? true : false;
        if (text && checkDateStart) {
            const dateSelected = new Date(text);
            if (!isNaN(checkDate02)) {
                if (checkDate01 <= checkDate02) {
                    setStartDate(dateSelected);
                    setStartDateError("");
                    setEndDateError("");
                } else {
                    if (checkDateStart) {
                        setStartDate(subtractDate);
                    } else {
                        setStartDate(dateSelected);
                    }
                    setStartDateError("");
                }
            } else {
                setStartDate(dateSelected);
                setStartDateError("");
            }
        } else {
            setStartDate("");
            setStartDateError("Campo obrigatório");
        }
    };

    const handleEndDate = (text) => {
        const checkDate01 = moment(text, "YYYY-MM-DD").valueOf();
        const checkDate02 = moment(startDate, "YYYY-MM-DD").valueOf();
        const addDay = moment(startDate).add(1, "days").format("YYYY-MM-DD");
        const checkDateEnd =
            moment(text, "YYYY-MM-DD").valueOf() > 0 ? true : false;
        if (text && checkDateEnd) {
            const dateSelected = new Date(text);
            if (!isNaN(checkDate02)) {
                if (checkDate01 >= checkDate02) {
                    setEndDate(dateSelected);
                    setEndDateError("");
                    setStartDateError("");
                } else {
                    if (checkDateEnd) {
                        setEndDate(addDay);
                    } else {
                        setEndDate(dateSelected);
                    }
                    setEndDateError("");
                }
            } else {
                setEndDate(dateSelected);
                setEndDateError("");
            }
        } else {
            setEndDate("");
            setEndDateError("Campo obrigatório");
        }
    };

    const selecionarCidade = (idCidade) => {
        const infoData = municipios.find((val) => val.id == idCidade);
        if (infoData) {
            setNomeCidade(infoData.nome);
        } else {
            setNomeCidade("");
        }
        setMunicipioId(idCidade);
    };

    const selecionarBairro = (nomeBairro) => {
        const infoData = bairros.find((val) => val == nomeBairro);
        if (infoData) {
            setNomeBairro(nomeBairro);
        } else {
            setNomeBairro("");
        }
        setBairrosId(nomeBairro);
    };

    const handleBtnRelat = () => {
        const date01 = moment(startDate).format("YYYY-MM-DD");
        const date02 = moment(endDate).format("YYYY-MM-DD");

        const excel = `${BaseURL}api/relatorio/categoria?unidadeId=${
            unidadeId == 0 ? "" : unidadeId
        }&situacaoId=${situacaoId == 0 ? "" : situacaoId}&generoId=${
            generoId == 0 ? "" : generoId
        }&dataInicio=${date01}&dataFim=${date02}&servicoId=${
            servicoId == 0 ? "" : servicoId
        }&categoriaId=${
            categoriaId == 0 ? "" : categoriaId
        }&cidade=${nomeCidade}&bairro=${nomeBairro}&token=${token}`;

        console.log("DEBUG DATA: ", excel);

        window.open(excel);
    };

    const checkDate01 =
        moment(startDate, "YYYY-MM-DD").valueOf() > 0 ? true : false;
    const checkDate02 =
        moment(endDate, "YYYY-MM-DD").valueOf() > 0 ? true : false;
    const checkFilterSubmit =
        unidadeId != "" &&
        categoriaId != "" &&
        servicoId != "" &&
        municipioId != "" &&
        bairrosId != "" &&
        situacaoId != "" &&
        generoId != "" &&
        checkDate01 &&
        checkDate02
            ? false
            : true;

    // ***********************************************************************

    return (
        <ClientesProfissionaisProvider>
            <Grid>
                <BreadCrumb
                    data={{
                        page: `Categorias e Serviços`,
                        menu: {
                            item: "Relatórios",
                            link: "",
                        },
                    }}
                />
                <Title title="Relatório de Categorias e Serviços" />
                {/* **************************************************************** */}

                <div className={styles.containerGeral}>
                    <div className="justify-content-center align-items-center container">
                        <div className={`${styles.containerInput}`}>
                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Unidade
                                        </label>
                                        <select
                                            className="form-select"
                                            value={unidadeId}
                                            onChange={(e) =>
                                                setUnidadeId(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Selecione unidade
                                            </option>
                                            <option value="0" key="999">
                                                Todas
                                            </option>
                                            {unidadesServer.map(
                                                ({ id, nome }, key) => {
                                                    return (
                                                        <option
                                                            value={id}
                                                            key={key + 1}
                                                        >
                                                            {nome}
                                                        </option>
                                                    );
                                                },
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div />
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Categoria
                                        </label>
                                        <select
                                            className="form-select"
                                            value={categoriaId}
                                            onChange={(e) =>
                                                setCategoriaId(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Selecione categoria
                                            </option>
                                            <option value="0" key="999">
                                                Todas
                                            </option>
                                            {categorias.map((data, key) => {
                                                return (
                                                    <option
                                                        value={data.id}
                                                        key={key}
                                                    >
                                                        {data.nome}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Serviço
                                        </label>
                                        <select
                                            className="form-select"
                                            value={servicoId}
                                            onChange={(e) =>
                                                setServicoId(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Selecione serviço
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {servicos.map((data, key) => {
                                                return (
                                                    <option
                                                        value={data.id}
                                                        key={key}
                                                    >
                                                        {data.nome}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Cidade
                                        </label>
                                        <select
                                            className="form-select"
                                            value={municipioId}
                                            onChange={(e) =>
                                                selecionarCidade(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Selecione uma cidade
                                            </option>
                                            <option value="0" key="999">
                                                Todas
                                            </option>
                                            {municipios.map((data, key) => {
                                                return (
                                                    <option
                                                        value={data.id}
                                                        key={key}
                                                    >
                                                        {data.nome}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Bairro
                                        </label>
                                        <select
                                            className="form-select"
                                            value={bairrosId}
                                            onChange={(e) =>
                                                selecionarBairro(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Selecione um bairro
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {bairros.map((data, key) => {
                                                return (
                                                    <option
                                                        value={data}
                                                        key={key}
                                                    >
                                                        {data}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Status dos profissionais
                                        </label>
                                        <select
                                            className="form-select"
                                            value={situacaoId}
                                            onChange={(e) =>
                                                setSituacaoId(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Selecione status dos
                                                profissionais
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {situacao.map(
                                                ({ id, nome }, key) => {
                                                    return (
                                                        <option
                                                            value={id}
                                                            key={key + 1}
                                                        >
                                                            {limitString(nome)}
                                                        </option>
                                                    );
                                                },
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Gênero
                                        </label>
                                        <select
                                            className="form-select"
                                            value={generoId}
                                            onChange={(e) =>
                                                setGeneroId(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Selecione gênero
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {genero.map((data, key) => {
                                                return (
                                                    <option
                                                        value={data.id}
                                                        key={key}
                                                    >
                                                        {data.nome}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLineData}`}>
                                    <div className={styles.inputData}>
                                        <label className="form-label">
                                            Data Início
                                        </label>
                                        <MyDatePicker
                                            id="dataInicio"
                                            value={startDate}
                                            onChange={(e) => handleStartDate(e)}
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLineData}`}>
                                    <div className={styles.inputData}>
                                        <label className="form-label">
                                            Data Fim
                                        </label>
                                        <MyDatePicker
                                            id="dataFim"
                                            value={endDate}
                                            onChange={(e) => handleEndDate(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.bntGerarRelat}>
                                <button
                                    className={
                                        checkFilterSubmit
                                            ? styles.buttonRelatBlock
                                            : styles.buttonRelat
                                    }
                                    type="button"
                                    onClick={
                                        checkFilterSubmit
                                            ? () => {}
                                            : () => handleBtnRelat()
                                    }
                                >
                                    <span>Gerar relatório</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* **************************************************************** */}
            </Grid>
            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </ClientesProfissionaisProvider>
    );
}
